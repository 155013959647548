import { useQuery } from "@tanstack/react-query";

import * as config from "../../config";
import { useBlockchainClient } from "../../context/blockchain";
import { getKeyPair, getUserFromStorage } from "../../context/session";
import type { LoggedInUser } from "../../types/models/auth";
import * as monitoring from "../../utils/monitoring";
import type { IRequestError } from "..";
import type { UseQueryOptions } from "../types";
import { BlockchainClient, KeyPair } from "./client";

const getSessionExpirationBlock = (
  blockchainClient: BlockchainClient,
  keyPair: KeyPair
) =>
  blockchainClient.query<number>("auth.get_session_expiration_block", {
    pubkey: keyPair.pubKey.toString("hex"),
  });

const renewSession = (
  blockchainClient: BlockchainClient,
  keyPair: KeyPair
): Promise<unknown> => {
  monitoring.addBreadcrumb({
    category: "auth",
    message: "auth.renew_session",
    level: "debug",
  });

  return blockchainClient.renewSession(keyPair);
};

type RenewAuth = { keyPair: KeyPair; user: LoggedInUser } | null;

const useRenewSessionQuery = (
  options?: UseQueryOptions<unknown, IRequestError, RenewAuth>
) => {
  const blockchainClient = useBlockchainClient();

  return useQuery<unknown, IRequestError, RenewAuth>(
    ["renewSession"],
    async () => {
      monitoring.addBreadcrumb({
        category: "auth",
        message: "useRenewSessionQuery",
        level: "debug",
      });

      try {
        const keyPairFromStorage = getKeyPair();
        const userFromStorage = getUserFromStorage();

        if (keyPairFromStorage === undefined || userFromStorage === undefined) {
          monitoring.addBreadcrumb({
            category: "auth",
            message: "No valid session found",
            level: "debug",
          });

          return null;
        }
        const [_currentBlock, _sessionExpirationBlock] =
          await Promise.allSettled([
            await blockchainClient.getBlockHeight(),
            await getSessionExpirationBlock(
              blockchainClient,
              keyPairFromStorage
            ),
          ]);

        if (_currentBlock.status === "rejected") {
          monitoring.addBreadcrumb({
            category: "auth",
            message: "getBlockHeight failed",
            data:
              _currentBlock.reason instanceof Error
                ? {
                    message: _currentBlock.reason.message,
                    name: _currentBlock.reason.name,
                  }
                : undefined,
            level: "debug",
          });
          return null;
        }

        if (_sessionExpirationBlock.status === "rejected") {
          monitoring.addBreadcrumb({
            category: "auth",
            message: "getSessionExpirationBlock failed",
            data:
              _sessionExpirationBlock.reason instanceof Error
                ? {
                    message: _sessionExpirationBlock.reason.message,
                    name: _sessionExpirationBlock.reason.name,
                  }
                : undefined,
            level: "debug",
          });
          return null;
        }

        const currentBlock = _currentBlock.value;
        const sessionExpirationBlock = _sessionExpirationBlock.value;

        if (sessionExpirationBlock <= currentBlock) {
          monitoring.addBreadcrumb({
            category: "auth",
            message: "sessionExpirationBlock <= currentBlock",
            level: "debug",
          });

          return null;
        }
        if (
          currentBlock >
          sessionExpirationBlock - config.renewSessionsWhenBlocksLeft
        ) {
          monitoring.addBreadcrumb({
            category: "auth",
            message: "renewing session",
            level: "debug",
          });

          await renewSession(blockchainClient, keyPairFromStorage);
        }

        return {
          keyPair: keyPairFromStorage,
          user: userFromStorage,
        }; // do nothing?
      } catch (err) {
        monitoring.addBreadcrumb({
          category: "auth",
          message: "renewing session",
          data: err instanceof Error ? { message: err.message } : undefined,
          level: "debug",
        });

        return null;
      }
    },
    options
  );
};

export { useRenewSessionQuery };
