import { FilePdf } from "@phosphor-icons/react";
import type React from "react";

import type { LedgerVersionDetails } from "../../../pages/CompanyShares/useLedgerVersions";
import { clsxm } from "../../../utils/tailwind";
import { SelectVersion } from "../../SelectVersion";
import { ExcelIcon } from "../icons";
import { Menu } from "../Menu";

type BottomBarProps = {
  selectedVersion?: LedgerVersionDetails;
  setSelectedVersion: (version?: LedgerVersionDetails) => void;
  ledgerVersions: LedgerVersionDetails[];
  enableExcelDownload: boolean;
  isDownloadLoading: boolean;
  downloadPdf: () => void;
  downloadExcel: () => void;
};

const BottomBar: React.FC<BottomBarProps> = ({
  selectedVersion,
  setSelectedVersion,
  ledgerVersions,
  enableExcelDownload,
  isDownloadLoading,
  downloadPdf,
  downloadExcel,
}) => {
  return (
    <div
      id="bottom-bar"
      className="tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-flex tw-gap-2 tw-border-t-[1px] tw-bg-white tw-px-4 tw-py-3 sm:tw-hidden"
    >
      <SelectVersion
        selectedVersion={selectedVersion}
        availableVersions={ledgerVersions}
        onChange={setSelectedVersion}
        menuPlacement="top"
        className="tw-w-full"
      />

      <Menu>
        <Menu.Button
          type="button"
          className="tw-rounded tw-p-3"
          isDropdown={false}
          isLoading={isDownloadLoading}
          onClick={() => downloadPdf()}
        >
          <FilePdf className="tw-h-6 tw-w-6" />
        </Menu.Button>
      </Menu>
      <Menu>
        <Menu.Button
          className={clsxm("tw-rounded tw-p-3", {
            "tw-cursor-not-allowed tw-opacity-50": !enableExcelDownload,
          })}
          disabled={!enableExcelDownload}
          isDropdown={false}
          type="button"
          onClick={() => downloadExcel()}
        >
          <ExcelIcon className="tw-h-6 tw-w-6" />
        </Menu.Button>
      </Menu>
    </div>
  );
};

export { BottomBar };
