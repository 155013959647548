import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useAddEntityRelationshipMutation } from "../../../api/rest/entities";
import type { CompanyInformation } from "../../../types/models/administration";
import type { CompanyInvolvement } from "../../../types/models/company";
import type { Entity } from "../../../types/models/entities";
import type { ButtonProps } from "../../design-system/Button";
import { Button } from "../../design-system/Button";
import { Dialog } from "../../design-system/Dialog";
import { FormError, FormGroup, FormLabel } from "../../design-system/FormGroup";
import { PlusIcon } from "../../design-system/icons";
import { SelectEntity } from "../../design-system/SelectEntity";

type AddRepresentativesProps = {
  currentCompany: CompanyInvolvement | CompanyInformation;
  onSuccess: () => void;
  entity: Entity;
  entities: Entity[];
  existingRepresentatives: Entity[];
};

type SelectRepresentativeForm = {
  representatives: string[];
};

const AttachRepresentatives = ({
  currentCompany,
  onSuccess,
  entity,
  entities,
  existingRepresentatives,
}: AddRepresentativesProps): JSX.Element => {
  const { handleSubmit, control } = useForm<SelectRepresentativeForm>({
    mode: "onChange",
    defaultValues: {
      representatives: [],
    },
  });
  const [isOpen, setOpen] = useState(false);
  const addEntityRelationshipMutation = useAddEntityRelationshipMutation(
    currentCompany.orgNumber,
    entity.id,
    {
      onSuccess: () => {
        onClose();
        onSuccess();
      },
    }
  );
  const formId = "attach-representative-form";
  const i18n = useTranslation();
  const buttonProps: ButtonProps = {
    variant: "outline",
    prefix: <PlusIcon />,
    children: i18n.t("label.addRepresentatives.existing"),
  };
  const onClose = () => {
    setOpen(false);
    addEntityRelationshipMutation.reset();
  };
  const existingRepresentativeIds = existingRepresentatives.map((x) => x.refId);
  const addEntitiesList = entities.filter(
    (x) =>
      entity.refId !== x.refId &&
      !existingRepresentativeIds.includes(x.refId) &&
      x.type === "Private"
  );

  return (
    <>
      <Button
        {...buttonProps}
        onClick={() => {
          setOpen(true);
        }}
      />
      {isOpen && (
        <Dialog
          isOpen
          actions={
            <>
              <Button
                disabled={addEntityRelationshipMutation.isLoading}
                onClick={onClose}
              >
                {i18n.t("label.cancel")}
              </Button>
              <Button
                color="primary"
                form={formId}
                isLoading={addEntityRelationshipMutation.isLoading}
                type="submit"
                variant="solid"
              >
                {i18n.t("label.save")}
              </Button>
            </>
          }
          isLoading={addEntityRelationshipMutation.isLoading}
          size="md"
          title={i18n.t("label.addRepresentatives.existing")}
          onClose={onClose}
        >
          <form
            className="tw-pb-4"
            id={formId}
            onSubmit={(event) => {
              event.stopPropagation();
              return handleSubmit((data) => {
                addEntityRelationshipMutation.mutate(data.representatives);
              })(event);
            }}
          >
            <div className="tw-pb-1">
              <FormGroup>
                <FormLabel htmlFor="representatives">
                  {i18n.t(
                    entity.type === "Company"
                      ? "label.representatives"
                      : "label.trustees"
                  )}
                </FormLabel>
                <Controller
                  control={control}
                  name="representatives"
                  render={({ field: { onChange, value }, fieldState }) => (
                    <>
                      <SelectEntity
                        isMulti
                        isClearable
                        menuPlacement="auto"
                        menuPosition="fixed"
                        options={addEntitiesList}
                        value={value}
                        onChange={(newValue) => {
                          if (Array.isArray(newValue)) {
                            onChange(newValue);
                          }
                        }}
                      />
                      <FormError>{fieldState.error?.message}</FormError>
                    </>
                  )}
                  rules={{
                    required: i18n.t("error.validation.required"),
                  }}
                />
              </FormGroup>
            </div>
          </form>
        </Dialog>
      )}
    </>
  );
};

export { AttachRepresentatives };
