import { useTranslation } from "react-i18next";

import type { useUpdateUsersMutation } from "../../api/rest/users";
import type { UserRole } from "../../types/models/users";
import { formatRefId } from "../../utils/format";
import { Button } from "../design-system/Button";
import { Dialog } from "../design-system/Dialog";
import { FormErrorList } from "../design-system/FormGroup";

type UpgradeUsersDialogProps = {
  onClose: () => void;
  mutation: ReturnType<typeof useUpdateUsersMutation>;
  allUserIds: string[];
  viewerApprovers: UserRole[];
};

const UpgradeUserModal: React.FunctionComponent<UpgradeUsersDialogProps> = ({
  onClose,
  mutation,
  allUserIds,
  viewerApprovers,
}) => {
  const i18n = useTranslation();
  const { isLoading, error } = mutation;

  return (
    <Dialog
      isOpen
      title={i18n.t("label.upgradeUsers.title")}
      onClose={onClose}
      isLoading={isLoading}
      actions={
        <>
          <Button onClick={onClose} disabled={isLoading}>
            {i18n.t("label.cancel")}
          </Button>
          <Button
            type="button"
            variant="solid"
            color="primary"
            isLoading={isLoading}
            onClick={() =>
              mutation.mutate({
                Role: "Editor",
                UserIds: allUserIds,
              })
            }
          >
            {i18n.t("label.upgradeUsers.button")}
          </Button>
        </>
      }
    >
      <p className="tw-pb-2">{i18n.t("label.upgradeUsers.description")}</p>
      <div className="tw-flex tw-flex-col tw-gap-y-2">
        {viewerApprovers.map((approver) => {
          return (
            <div
              className="tw-flex tw-items-center tw-justify-between"
              key={approver.user.id}
            >
              <p>{approver.user.name}</p>
              <span className="tw-text-gray-500">
                {formatRefId({
                  refId: approver.user.refId,
                  countryCode: approver.user.countryCode,
                  type: "Private",
                })}
              </span>
            </div>
          );
        })}
      </div>
      {error && <FormErrorList error={error} />}
    </Dialog>
  );
};

export { UpgradeUserModal };
