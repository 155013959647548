import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Button } from "../../../components/design-system/Button";
import { Description } from "../../../components/design-system/Description";
import {
  FormError,
  FormGroup,
  FormLabel,
} from "../../../components/design-system/FormGroup";
import { PlusIcon } from "../../../components/design-system/icons";
import { Input } from "../../../components/design-system/Input";
import { Select } from "../../../components/design-system/Select";
import { PageWrapper } from "../../../components/PageWrapper";
import { SelectCurrency } from "../../../components/SelectCurrency";
import { OptionsProgram } from "../../../types/models/options";
import { ShareType } from "../../../types/models/shares";
import { dateToIsoString } from "../../../utils/date";
import { monthsBetween } from "../Options.utils";
import { CreateShareClassDialog } from "./CreateShareClassDialog";

const LOCALSTORAGE_KEY = "draftOptions";

function saveStateToLocalStorage(orgNumber: string, state: OptionsProgram) {
  const current = loadStateFromLocalStorage(orgNumber);
  localStorage.setItem(
    LOCALSTORAGE_KEY,
    JSON.stringify({ ...current, [orgNumber]: state })
  );
}

function loadStateFromLocalStorage(
  orgNumber: string
): OptionsProgram | undefined {
  const state =
    localStorage.getItem(LOCALSTORAGE_KEY) &&
    JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY) ?? "{}");
  return state && state[orgNumber];
}

function deleteStateFromLocalStorage() {
  localStorage.removeItem(LOCALSTORAGE_KEY);
}

type DetailsProps = {
  orgNumber: string;
  shareTypes: ShareType[];
  form: string;
  selectedProgram?: OptionsProgram;
  nextStep: () => void;
  setDetails: (data: OptionsProgram) => void;
};

const Details = ({
  orgNumber,
  form,
  shareTypes,
  selectedProgram,
  nextStep,
  setDetails,
}: DetailsProps) => {
  const i18n = useTranslation();
  const [showCreateShareClass, setShowCreateShareClass] = useState(false);
  const [tempShareClasses, setTempShareClasses] = useState<ShareType[]>([]);
  const typeOptions = [
    { value: "Warrants", label: i18n.t("options.type.to") },
    { value: "EmployeeStockOptions", label: i18n.t("options.type.kpo") },
  ];
  const shareTypeOptions = [...shareTypes, ...tempShareClasses].map(
    ({ name }) => ({
      value: name,
      label: name,
    })
  );

  const defaults: OptionsProgram = selectedProgram ||
    loadStateFromLocalStorage(orgNumber) || {
      title: "",
      type: "Warrants",
      totalAmountOfOptions: undefined,
      currency: "SEK",
      startDate: "",
      strikeStartDate: "",
      strikeEndDate: "",
      shareType: "",
      optionPrice: undefined,
      strikePrice: undefined,
    };
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useForm({
    mode: "onChange",
    defaultValues: defaults,
  });
  const formValues = watch();

  function addYears(date: string, years: number) {
    const d = new Date(date);
    d.setFullYear(d.getFullYear() + years);
    return dateToIsoString(d);
  }

  function addDays(date: string, days: number) {
    const d = new Date(date);
    d.setDate(d.getDate() + days);
    return dateToIsoString(d);
  }

  return (
    <PageWrapper className="tw-flex tw-h-[calc(100vh-10rem)] tw-max-w-3xl tw-flex-col tw-justify-start tw-px-0 tw-pb-0 tw-pt-6">
      <form
        onSubmit={(event) => {
          event.stopPropagation();

          return handleSubmit((data) => {
            if (!selectedProgram) {
              saveStateToLocalStorage(orgNumber, data);
            }
            setDetails(data);
            nextStep();
          })(event);
        }}
        id={form}
      >
        <div className="tw-h-full tw-space-y-6 tw-overflow-auto tw-px-4 tw-pb-4">
          <h2 className="tw-pb-4">{i18n.t("options.create.title")}</h2>
          <FormGroup>
            <FormLabel htmlFor="title">
              {i18n.t("options.create.name")}
            </FormLabel>
            <Input
              id="title"
              {...register("title", {
                validate: (value) => {
                  if (!value?.trim()) {
                    return i18n.t("error.validation.required");
                  }

                  return true;
                },
              })}
            />
            <FormError>{errors.title?.message}</FormError>
          </FormGroup>
          <Controller
            name="type"
            control={control}
            rules={{ required: i18n.t("error.validation.required") }}
            render={({ field: { value, onChange, onBlur }, fieldState }) => (
              <FormGroup>
                <FormLabel htmlFor="type">
                  {i18n.t("options.create.type")}
                </FormLabel>
                <Select
                  name="type"
                  value={typeOptions.find((option) => option.value === value)}
                  onChange={(option) => {
                    if (option) {
                      onChange(option.value);
                    }
                  }}
                  onBlur={onBlur}
                  isDisabled={!!selectedProgram}
                  options={typeOptions}
                />
                <FormError>{fieldState.error?.message}</FormError>
              </FormGroup>
            )}
          />
          <FormGroup>
            <FormLabel htmlFor="totalAmountOfOptions">
              {i18n.t("options.create.max")}
            </FormLabel>
            <Input
              id="totalAmountOfOptions"
              {...register("totalAmountOfOptions", {
                required: i18n.t("error.validation.required"),
                valueAsNumber: true,
                min: {
                  value: 1,
                  message: i18n.t("error.validation.range.min", { min: 1 }),
                },
              })}
              type="number"
              min={1}
            />
            <FormError>{errors.totalAmountOfOptions?.message}</FormError>
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor="startDate">
              {i18n.t("options.create.startDate")}
            </FormLabel>
            <Controller
              control={control}
              render={({
                field: { ref, name, onChange, value },
                fieldState,
              }) => (
                <>
                  <Input
                    id="startDate"
                    value={value}
                    ref={ref}
                    name={name}
                    onChange={onChange}
                    type="date"
                    className="tw-w-full"
                    max="9999-12-31"
                  />
                  <FormError>{fieldState.error?.message}</FormError>
                </>
              )}
              name="startDate"
              rules={{ required: i18n.t("error.validation.required") }}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor="currency">{i18n.t("label.currency")}</FormLabel>
            <Controller
              name="currency"
              control={control}
              render={({ field: { onChange, value }, fieldState }) => (
                <>
                  <SelectCurrency value={value} onChange={onChange} />
                  <FormError>{fieldState.error?.message}</FormError>
                </>
              )}
              rules={{ required: i18n.t("error.validation.required") }}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel
              htmlFor="optionPrice"
              isOptional={formValues.type !== "Warrants"}
            >
              {i18n.t("options.create.optionPrice")}
            </FormLabel>
            <Input
              id="optionPrice"
              {...register("optionPrice", {
                required:
                  formValues.type === "Warrants" &&
                  i18n.t("error.validation.required"),
                valueAsNumber: true,
                min: {
                  value: 0,
                  message: i18n.t("error.validation.range.min", { min: 0 }),
                },
              })}
              type="number"
              step=".01"
              min={0}
            />
            <FormError>{errors.optionPrice?.message}</FormError>
          </FormGroup>
          <div className="tw-grid tw-gap-4 md:tw-grid-cols-2">
            <FormGroup>
              <FormLabel htmlFor="strikeStartDate">
                {i18n.t("options.create.excersizeStart")}
              </FormLabel>
              <Controller
                control={control}
                render={({
                  field: { ref, name, onChange, value },
                  fieldState,
                }) => (
                  <>
                    <Input
                      id="strikeStartDate"
                      value={value}
                      ref={ref}
                      name={name}
                      onChange={onChange}
                      type="date"
                      className="tw-w-full"
                      min={
                        formValues.startDate &&
                        (formValues.type === "EmployeeStockOptions"
                          ? addYears(formValues.startDate, 3)
                          : formValues.startDate)
                      }
                      max="9999-12-31"
                    />
                    <FormError>{fieldState.error?.message}</FormError>
                  </>
                )}
                name="strikeStartDate"
                rules={{ required: i18n.t("error.validation.required") }}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel htmlFor="strikeEndDate">
                {i18n.t("options.create.excersizeEnd")}
              </FormLabel>
              <Controller
                control={control}
                render={({
                  field: { ref, name, onChange, value },
                  fieldState,
                }) => (
                  <>
                    <Input
                      id="strikeEndDate"
                      value={value}
                      ref={ref}
                      name={name}
                      onChange={onChange}
                      type="date"
                      className="tw-w-full"
                      min={
                        formValues.strikeStartDate &&
                        addDays(formValues.strikeStartDate, 1)
                      }
                      max="9999-12-31"
                    />
                    <FormError>{fieldState.error?.message}</FormError>
                  </>
                )}
                name="strikeEndDate"
                rules={{ required: i18n.t("error.validation.required") }}
              />
            </FormGroup>
          </div>
          <div className="tw-rounded tw-bg-neutral-100 tw-p-5">
            <div className="tw-grid md:tw-grid-cols-2">
              <Description
                title={i18n.t("options.create.vesting")}
                description={
                  formValues.startDate &&
                  formValues.strikeStartDate &&
                  `${i18n.t("options.create.period", {
                    total: monthsBetween(
                      new Date(formValues.startDate),
                      new Date(formValues.strikeStartDate)
                    ),
                  })}`
                }
                theme="grayBlack"
              />
              <Description
                title={i18n.t("options.create.duration")}
                description={
                  formValues.startDate &&
                  formValues.strikeEndDate &&
                  `${i18n.t("options.create.period", {
                    total: monthsBetween(
                      new Date(formValues.startDate),
                      new Date(formValues.strikeEndDate)
                    ),
                  })}`
                }
                theme="grayBlack"
              />
            </div>
          </div>
          <Controller
            name="shareType"
            control={control}
            rules={{ required: i18n.t("error.validation.required") }}
            render={({ field: { value, onChange, onBlur }, fieldState }) => (
              <FormGroup>
                <div className="tw-flex tw-justify-between">
                  <div className="tw-flex tw-items-end">
                    <FormLabel htmlFor="shareType">
                      {i18n.t("options.create.shareClass")}
                    </FormLabel>
                  </div>
                  <Button
                    size="sm"
                    variant="clean"
                    color="secondary"
                    prefix={<PlusIcon />}
                    onClick={() => setShowCreateShareClass(true)}
                  >
                    {i18n.t("label.addNew")}
                  </Button>
                </div>
                <Select
                  name="shareType"
                  menuPosition="fixed"
                  menuPlacement="auto"
                  value={shareTypeOptions.find(
                    (option) => option.value === value
                  )}
                  onChange={(option) => {
                    if (option) {
                      const tempClass = tempShareClasses.find(
                        (x) => x.name === option.value
                      );
                      setValue("newShareClass", tempClass);
                      onChange(option.value);
                    }
                  }}
                  onBlur={onBlur}
                  options={shareTypeOptions}
                />
                <FormError>{fieldState.error?.message}</FormError>
              </FormGroup>
            )}
          />
          <FormGroup>
            <FormLabel htmlFor="strikePrice">
              {i18n.t("options.create.sharePrice")}
            </FormLabel>
            <Input
              id="strikePrice"
              {...register("strikePrice", {
                required: i18n.t("error.validation.required"),
                valueAsNumber: true,
                min: {
                  value: 0.01,
                  message: i18n.t("error.validation.range.min", { min: 0.01 }),
                },
              })}
              type="number"
              step=".01"
              min={0.01}
            />
            <FormError>{errors.strikePrice?.message}</FormError>
          </FormGroup>
        </div>
      </form>
      {showCreateShareClass && (
        <CreateShareClassDialog
          onSubmit={(data) => {
            setShowCreateShareClass(false);
            setTempShareClasses([...tempShareClasses, data]);
            setValue("shareType", data.name);
            setValue("newShareClass", data);
          }}
          onClose={() => setShowCreateShareClass(false)}
          shareTypes={shareTypes}
        />
      )}
    </PageWrapper>
  );
};

export { deleteStateFromLocalStorage, Details };
