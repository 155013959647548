import { format } from "date-fns";
import { useTranslation } from "react-i18next";

import { useCompanyDetailsQuery } from "../../../../api/rest/administration";
import { LabelValue } from "../../../../components/design-system/LabelValue";
import { Loading } from "../../../../components/design-system/Loading";
import { NoData } from "../../../../components/NoData";
import type { CompanyInformation as CompanyInformationModel } from "../../../../types/models/administration";
import type { CompanyInvolvement } from "../../../../types/models/company";
import { getCountryName } from "../../../../utils/country";
import { getDateLocale } from "../../../../utils/date";
import {
  formatCurrency,
  formatNumber,
  formatRefId,
} from "../../../../utils/format";

type Props = {
  currentCompany: CompanyInvolvement | CompanyInformationModel;
};

type FormatTextProps = {
  text: string;
};

const FormatText = ({ text }: FormatTextProps) => {
  const lines = text.split("\\n");
  return (
    <ul>
      {lines.map((line) => (
        <li key={line}>
          {line.split(/\\t/g).map((part, j) =>
            j > 0 ? (
              <>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {part}
              </>
            ) : (
              <>{part}</>
            )
          )}
        </li>
      ))}
    </ul>
  );
};

const CompanyInformation = ({ currentCompany }: Props) => {
  const i18n = useTranslation();
  const dateLocale = getDateLocale();
  const {
    error,
    isLoading,
    data: companyInfo,
    isError,
    isSuccess,
  } = useCompanyDetailsQuery(currentCompany?.orgNumber);
  const { sharesInformation } = companyInfo || {};

  const shareClassWithLimits =
    sharesInformation?.shareClasses?.filter(
      (shareClass) => !!shareClass.shareLimits
    ) ?? [];
  const hasShareClassesLimits = shareClassWithLimits.length > 0;

  return (
    <div className="tw-space-y-2">
      {isError && (
        <NoData
          type="error"
          title={i18n.t("error.fetch")}
          description={i18n.t(error.errors[0]?.message.code)}
        />
      )}
      {isLoading && <Loading />}
      {isSuccess && companyInfo && (
        <div className="tw-space-y-4">
          {sharesInformation && (
            <>
              <h3 className="tw-flex tw-flex-row tw-flex-wrap tw-items-baseline tw-justify-between tw-border-b tw-pb-2">
                {i18n.t("label.shareInformation")}
                {companyInfo.lastUpdated && (
                  <span className="tw-text-sm tw-text-gray-500">
                    {i18n.t("label.updated")}{" "}
                    {format(new Date(companyInfo.lastUpdated), "P", {
                      locale: dateLocale,
                    })}
                  </span>
                )}
              </h3>
              <p className="tw-py-2 tw-text-sm tw-text-gray-500">
                {i18n.t("companyInformation.description")}
              </p>

              {sharesInformation.shareCapital && (
                <LabelValue
                  label={i18n.t("label.shareCapital")}
                  value={`${formatCurrency(
                    sharesInformation.shareCapital.amount
                  )} ${sharesInformation.shareCapital.currency}`}
                />
              )}
              {sharesInformation.numberOfShares && (
                <LabelValue
                  label={i18n.t("label.totalNrOfShares")}
                  value={`${formatNumber(
                    sharesInformation.numberOfShares
                  )} ${i18n.t("myShares.sharesSumUnit")}`}
                />
              )}
              {sharesInformation.shareLimits.capital && (
                <LabelValue
                  label={i18n.t("label.shareLimits")}
                  value={`${formatCurrency(
                    sharesInformation.shareLimits.capital.min
                  )} ${sharesInformation.shareLimits.currency} ${i18n.t(
                    "label.to"
                  )} ${formatCurrency(
                    sharesInformation.shareLimits.capital.max
                  )} ${sharesInformation.shareLimits.currency}`}
                />
              )}
              {sharesInformation.shareLimits.shares && (
                <LabelValue
                  label={i18n.t("label.shareAmountLimits")}
                  value={`${formatNumber(
                    sharesInformation.shareLimits.shares.min
                  )} ${i18n.t("myShares.sharesSumUnit")} - ${formatNumber(
                    sharesInformation.shareLimits.shares.max
                  )} ${i18n.t("myShares.sharesSumUnit")}`}
                />
              )}
              {sharesInformation.shareClasses.length > 0 && (
                <LabelValue
                  label={i18n.t("label.shareClasses")}
                  value={sharesInformation.shareClasses
                    .map(
                      (shareClass) =>
                        `${shareClass.name} (${formatNumber(
                          shareClass.amount
                        )})`
                    )
                    .join(", ")}
                />
              )}
              {hasShareClassesLimits && (
                <LabelValue
                  label={i18n.t("label.shareClassesLimits")}
                  value={shareClassWithLimits
                    .map(
                      (shareClass) =>
                        `${shareClass.name} (${formatNumber(
                          shareClass.shareLimits.min
                        )} ${i18n.t("myShares.sharesSumUnit")} - ${formatNumber(
                          shareClass.shareLimits.max
                        )} ${i18n.t("myShares.sharesSumUnit")})`
                    )
                    .join(", ")}
                />
              )}
              {sharesInformation.description && (
                <LabelValue
                  label={i18n.t("label.comment")}
                  value={sharesInformation.description}
                />
              )}
            </>
          )}

          <h3 className="tw-border-b tw-pb-2 tw-pt-4">
            {i18n.t("label.aboutCompany")}
          </h3>
          <LabelValue
            label={i18n.t("label.companyName")}
            value={companyInfo.name}
          />
          <LabelValue
            label={i18n.t("label.organizationId")}
            value={formatRefId({
              refId: companyInfo.orgNumber,
              countryCode: companyInfo.countryCode ?? "",
              type: "Company",
            })}
          />
          {companyInfo?.countryCode && (
            <LabelValue
              label={i18n.t("label.country")}
              value={getCountryName(companyInfo.countryCode)}
            />
          )}
          <LabelValue
            label={i18n.t("label.description")}
            value={<FormatText text={companyInfo.description ?? ""} />}
          />
          {companyInfo.contact?.address && (
            <LabelValue
              label={i18n.t("label.address")}
              value={`${companyInfo.contact.address.line1}${
                companyInfo.contact.address.line2
                  ? ` ${companyInfo.contact.address.line2}`
                  : ""
              }, ${companyInfo.contact.address.postalCode} ${
                companyInfo.contact.address.city
              } ${
                companyInfo?.countryCode
                  ? getCountryName(companyInfo?.countryCode)
                  : ""
              }`}
            />
          )}
          {companyInfo.contact?.email && (
            <LabelValue
              label={i18n.t("label.email")}
              value={companyInfo.contact.email}
            />
          )}
          {companyInfo.authorizedSignatory?.clearText && (
            <LabelValue
              label={i18n.t("label.signatory")}
              value={
                <FormatText text={companyInfo.authorizedSignatory.clearText} />
              }
            />
          )}
          {companyInfo.formationDate && (
            <LabelValue
              label={i18n.t("label.formationDate")}
              value={format(new Date(companyInfo.formationDate), "P", {
                locale: dateLocale,
              })}
            />
          )}
          {companyInfo.registrationDate && (
            <LabelValue
              label={i18n.t("label.registrationDate")}
              value={format(new Date(companyInfo.registrationDate), "P", {
                locale: dateLocale,
              })}
            />
          )}
        </div>
      )}
    </div>
  );
};

export { CompanyInformation };
