import type { PrivateHoldings } from "@capchapdev/rell-api";
import type { UseQueryResult } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";

import { useBlockchainClient } from "../../context/blockchain";
import { useSession } from "../../context/session";
import type { IRequestError } from "..";
import { getAuthHeader } from "../auth";

export type HoldingHistory = {
  type: string;
  date: string;
  addition: boolean;
  shares: {
    start: number;
    end: number;
    type: string;
  };
};

const usePrivateHoldings = (
  version: "approved" | "latest" = "latest"
): UseQueryResult<PrivateHoldings, IRequestError> => {
  const blockchainClient = useBlockchainClient();
  const session = useSession();
  const refId = session.user?.refId ?? "";
  const authHeader = getAuthHeader();

  return useQuery<PrivateHoldings, IRequestError, PrivateHoldings, string[]>(
    ["privateHoldings", version, refId],
    () =>
      blockchainClient.query<PrivateHoldings>("ledger.private_holdings", {
        auth: authHeader!,
        version,
      }),
    { enabled: refId.length > 0 && authHeader !== undefined }
  );
};

const usePrivateHistory = (
  orgNumber: string | undefined
): UseQueryResult<HoldingHistory[], IRequestError> => {
  const blockchainClient = useBlockchainClient();
  const session = useSession();
  const refId = session.user?.refId ?? "";
  const authHeader = getAuthHeader();

  return useQuery<HoldingHistory[], IRequestError, HoldingHistory[], string[]>(
    ["privateHoldingHistory", orgNumber!, refId],
    () =>
      blockchainClient.query<HoldingHistory[]>(
        "ledger.private_holding_history",
        { auth: authHeader!, org_no: orgNumber! }
      ),
    { enabled: !!orgNumber && refId.length > 0 && authHeader !== undefined }
  );
};

export { usePrivateHistory, usePrivateHoldings };
