import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useParentEventsQuery } from "../../api/blockchain/events";
import type { CompanyInformation } from "../../types/models/administration";
import type { CompanyInvolvement } from "../../types/models/company";
import type { EntitiesMap } from "../../types/models/entities";
import type { TParentEvent } from "../../types/models/events";
import type { LedgerVersion } from "../../types/models/shares";
import { getEventsWithLabel } from "../../utils/events-utils";
import { CollapseIcon, ExpandIcon } from "../design-system/icons";
import { List, ListItem } from "../design-system/List";
import { Menu } from "../design-system/Menu";
import { AuditEvent } from "../EventList/AuditEvent";
import { sortEvents } from "../History/History.utils";
import { IconDescription } from "../IconDescription";
import { SelectionMenuItem } from "../SelectionMenuItem";

type SortBy = "date-asc" | "date-desc";

const useExpandEvents = (events: TParentEvent[]) => {
  const getEventsWithState = (
    filteredEvents: TParentEvent[],
    isExpanded: boolean
  ): Record<string, boolean> =>
    Object.fromEntries(
      Object.values(filteredEvents).map(({ id }) => [id, isExpanded])
    );

  const allEventsCollapsed = getEventsWithState(events, false);
  const allEventsExpanded = getEventsWithState(events, true);

  const [expandedEvents, setExpandedEvents] =
    useState<ReturnType<typeof getEventsWithState>>(allEventsCollapsed);

  const collapseAll = () => {
    setExpandedEvents(allEventsCollapsed);
  };
  const expandAll = () => {
    setExpandedEvents(allEventsExpanded);
  };

  const isExpanded = (id: string) => expandedEvents[id] ?? false;

  const expandById = (id: string) => (open: boolean) => {
    setExpandedEvents({
      ...expandedEvents,
      [id]: open,
    });
  };

  return {
    isExpanded,
    expandById,
    collapseAll,
    expandAll,
  };
};

const AuditLog = ({
  currentCompany,
  currentVersion: _currentVersion,
  entitiesMap,
}: {
  currentCompany: CompanyInvolvement | CompanyInformation;
  /** @deprecated This is unused. Either use it, or remove it */
  currentVersion: LedgerVersion;
  entitiesMap: EntitiesMap;
}) => {
  const eventsQuery = useParentEventsQuery({
    orgNumber: currentCompany.orgNumber,
    offset: 0,
    limit: Number.MAX_SAFE_INTEGER,
  });
  const i18n = useTranslation();
  const sortMenuItems: Record<SortBy, string> = {
    "date-asc": i18n.t("sortBy.date.asc"),
    "date-desc": i18n.t("sortBy.date.desc"),
  };

  const [sortBy, setSortBy] = useState<SortBy>("date-desc");
  const events = useMemo(
    () => (eventsQuery.isSuccess ? eventsQuery.data.data : []),
    [eventsQuery]
  );

  const sortedFilteredEvents = useMemo(() => {
    return sortEvents(events, sortBy);
  }, [events, sortBy]);

  const finalEvents = getEventsWithLabel(sortedFilteredEvents);

  const { isExpanded, expandById, collapseAll, expandAll } =
    useExpandEvents(sortedFilteredEvents);

  return (
    <div className="tw-space-y-4">
      <div className="tw-flex tw-flex-col tw-content-start tw-gap-2 print:tw-hidden max-sm:tw-hidden md:tw-flex-row">
        <div className="tw-flex tw-flex-1 tw-items-center tw-justify-start tw-gap-2 max-md:tw-hidden">
          <Menu>
            <Menu.Button>{i18n.t("label.sortBy")}</Menu.Button>
            <Menu.Items align="bottomLeft">
              {Object.entries(sortMenuItems).map(([key, label]) => (
                <Menu.Item
                  key={key}
                  as="button"
                  onClick={() => setSortBy(key as SortBy)}
                >
                  <SelectionMenuItem checked={sortBy === key}>
                    {label}
                  </SelectionMenuItem>
                </Menu.Item>
              ))}
            </Menu.Items>
          </Menu>
          <Menu>
            <Menu.Button>{i18n.t("label.view")}</Menu.Button>
            <Menu.Items align="bottomLeft" className="tw-divide-y">
              <div>
                <Menu.Item closeOnClick={false} onClick={expandAll}>
                  <IconDescription
                    icon={<ExpandIcon className="tw-h-5 tw-w-5" />}
                    title={
                      <span className="tw-text-base">
                        {i18n.t("label.expandAll")}
                      </span>
                    }
                  />
                </Menu.Item>
                <Menu.Item closeOnClick={false} onClick={collapseAll}>
                  <IconDescription
                    icon={<CollapseIcon className="tw-h-5 tw-w-5" />}
                    title={
                      <span className="tw-text-base">
                        {i18n.t("label.collapseAll")}
                      </span>
                    }
                  />
                </Menu.Item>
              </div>
            </Menu.Items>
          </Menu>
        </div>
      </div>
      <div className="max-sm:tw-pb-32">
        <List>
          {finalEvents.map((event) => (
            <ListItem key={event.id} className="tw-py-0">
              <AuditEvent
                currentCompany={currentCompany}
                entitiesMap={entitiesMap}
                event={event}
                label={event.label}
                open={isExpanded(event.id)}
                onOpenChange={expandById(event.id)}
              />
            </ListItem>
          ))}
        </List>
      </div>
    </div>
  );
};

export { AuditLog };
