import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useActiveApprovalRuleQuery } from "../../../../../api/blockchain/company";
import { getApprovalPolicyRule } from "../../../../../api/rest/approval-rule-policy";
import { useEntitiesQuery } from "../../../../../api/rest/entities";
import { Button } from "../../../../../components/design-system/Button";
import { Loading } from "../../../../../components/design-system/Loading";
import { useSession } from "../../../../../context/session";
import { useCompanyEvents } from "../../../../../hooks/useCompanyEvents";
import type { CompanyInformation } from "../../../../../types/models/administration";
import type { ProgressItem } from "../../../../../types/models/approval";
import type { CompanyInvolvement } from "../../../../../types/models/company";
import { hasRequiredPermission } from "../../../../../utils/permissions";
import type { Policy } from "../../../../../utils/policy";
import EmptyState from "./EmptyState";
import PendingApprovalProposal from "./PendingApprovalProposal";
import PolicyDetails from "./PolicyDetails";

type ApprovalPolicyProgressProps =
  | {
      isPendingApproval: true;
      policy: Policy;
      isPendingCurrentUserApproval: boolean;
      progress: [ProgressItem, ...ProgressItem[]];
    }
  | { isPendingApproval: false };

type EntitiesProps = {
  currentCompany: CompanyInvolvement | CompanyInformation;
};

type ApprovalPolicyProps = ApprovalPolicyProgressProps &
  EntitiesProps & {
    boardShouldApproveLedgerAsOfficial: boolean;
  };

const ApprovalPolicy: React.FunctionComponent<ApprovalPolicyProps> = ({
  currentCompany,
  boardShouldApproveLedgerAsOfficial,

  ...props
}) => {
  const i18n = useTranslation();
  const { user } = useSession();
  const { pendingEvents, pendingRollbackEvents } = useCompanyEvents(
    currentCompany.orgNumber
  );
  const entitiesQuery = useEntitiesQuery(currentCompany.orgNumber);

  const entitiesData = entitiesQuery.data || [];

  const activeApprovalRule = useActiveApprovalRuleQuery(
    currentCompany.orgNumber
  );

  if (activeApprovalRule.isLoading) {
    return <Loading />;
  }

  const ruleToCurrentPolicy =
    activeApprovalRule.data === undefined
      ? undefined
      : getApprovalPolicyRule(activeApprovalRule.data);

  if (ruleToCurrentPolicy === undefined) {
    return (
      <>Error loading approval policy. Has the company been set up properly?</>
    );
  }

  if (ruleToCurrentPolicy === "None" && !props.isPendingApproval) {
    return <EmptyState />;
  }

  return (
    <div data-testid="approval-policy">
      <div className="tw-flex tw-flex-col">
        {props.isPendingApproval && (
          <PendingApprovalProposal
            policy={props.policy}
            progress={props.progress}
            orgNumber={currentCompany.orgNumber}
            entities={entitiesData}
            boardShouldApproveLedgerAsOfficial={
              boardShouldApproveLedgerAsOfficial
            }
            className="tw-mb-8"
          />
        )}
        {ruleToCurrentPolicy !== "None" && (
          <>
            <div className="tw-flex tw-flex-col tw-gap-4 tw-pb-8">
              <h2 className=" tw-font-body tw-text-2xl tw-leading-tight">
                {i18n.t("label.approvalPolicy")}
              </h2>
              <p className="tw-text-sm tw-leading-normal">
                {i18n.t("approvalPolicy.description")}
              </p>
            </div>
            <div className="tw-flex tw-flex-col tw-gap-4 tw-border-t tw-py-8">
              <h3 className="tw-font-body tw-text-lg tw-leading-tight md:tw-text-2xl">
                {i18n.t("approvalPolicy.currentPolicy")}
              </h3>
              <div
                className="tw-flex tw-place-items-center tw-gap-4 tw-rounded tw-bg-kvanta-background tw-p-5"
                data-testid="current-policy"
              >
                <PolicyDetails
                  rule={ruleToCurrentPolicy}
                  entities={entitiesData}
                  percentage={
                    activeApprovalRule.data?.rule === "BoardPercentage"
                      ? activeApprovalRule.data.percentage / 10
                      : undefined
                  }
                  approverIds={
                    activeApprovalRule.data?.rule === "SpecificUsers"
                      ? activeApprovalRule.data.users
                          ?.map((u) => u.id?.toString())
                          .filter(Boolean)
                      : undefined
                  }
                />
                {hasRequiredPermission(
                  "Administrator",
                  currentCompany,
                  user
                ) && (
                  <div>
                    <Button variant="clean" disabled={pendingEvents.length > 0}>
                      {pendingEvents.length > 0 ||
                      pendingRollbackEvents.length > 0 ? (
                        <span className="tw-p-0 tw-text-sm tw-text-gray-400">
                          {i18n.t("action.change")}
                        </span>
                      ) : (
                        <Link
                          className="tw-p-0 tw-text-sm tw-text-kvanta-link"
                          to="./change"
                        >
                          {i18n.t("action.change")}
                        </Link>
                      )}
                    </Button>
                  </div>
                )}
              </div>
              {pendingEvents.length > 0 && (
                <p className="tw-text-sm">
                  {i18n.t("approvalPolicy.ledgerApprovalRequired")}
                </p>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export { ApprovalPolicy };
export type { ApprovalPolicyProgressProps };
