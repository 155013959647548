import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useUsersQuery } from "../../../api/blockchain/users";
import { useEntitiesQuery } from "../../../api/rest/entities";
import type {
  useAddUserMutation,
  useUpdateUsersMutation,
} from "../../../api/rest/users";
import { CompanyInformation } from "../../../types/models/administration";
import { CompanyInvolvement } from "../../../types/models/company";
import type { NewUserRole } from "../../../types/models/users";
import { Button } from "../../design-system/Button";
import { Dialog } from "../../design-system/Dialog";
import {
  FormError,
  FormErrorList,
  FormGroup,
  FormLabel,
} from "../../design-system/FormGroup";
import { SelectEntity } from "../../design-system/SelectEntity";
import { AddEntity } from "../../Entities/AddEntity";
import { SelectRole } from "../../SelectRole";

const formId = "edit-user-form";

type EditUserDialogProps = {
  title: string;
  onClose: () => void;
  handleOnSubmit: (data: NewUserRole) => void;
  mutation:
    | ReturnType<typeof useUpdateUsersMutation>
    | ReturnType<typeof useAddUserMutation>;
  defaultValues?: NewUserRole;
  currentCompany: CompanyInvolvement | CompanyInformation;
  isApprover?: boolean;
};

const EditUserDialog = ({
  title,
  onClose,
  handleOnSubmit,
  mutation,
  defaultValues,
  currentCompany,
  isApprover,
}: EditUserDialogProps) => {
  const i18n = useTranslation();
  const entitiesQuery = useEntitiesQuery(currentCompany.orgNumber);
  const usersQuery = useUsersQuery(currentCompany.orgNumber);
  const existingUserIds = (usersQuery.data || []).map((x) => x.user.id);
  const entitiesList = (entitiesQuery.data || []).filter(
    (x) =>
      x.type === "Private" &&
      (!existingUserIds.includes(x.id) || x.id === defaultValues?.entityId)
  );

  const { handleSubmit, control } = useForm<NewUserRole>({
    mode: "onChange",
    defaultValues,
  });
  const { isLoading } = mutation;

  return (
    <Dialog
      isOpen
      title={title}
      onClose={onClose}
      isLoading={isLoading}
      actions={
        <>
          <Button onClick={onClose} disabled={isLoading}>
            {i18n.t("label.cancel")}
          </Button>
          <Button
            type="submit"
            variant="solid"
            color="primary"
            isLoading={isLoading}
            form={formId}
          >
            {i18n.t("label.save")}
          </Button>
        </>
      }
    >
      <form
        className="tw-space-y-4"
        onSubmit={(event) => {
          event.stopPropagation();
          return handleSubmit((data) => handleOnSubmit(data))(event);
        }}
        id={formId}
      >
        <FormGroup>
          <Controller
            name="entityId"
            control={control}
            render={({ field: { onChange, value }, fieldState }) => (
              <>
                <div className="tw-grid tw-grid-cols-2">
                  <FormLabel
                    htmlFor="entityId"
                    className="tw-grid tw-align-text-bottom"
                  >
                    {i18n.t("label.person")}
                  </FormLabel>
                  {!defaultValues?.entityId && (
                    <div className="tw-justify-self-end">
                      <AddEntity
                        currentCompany={currentCompany}
                        onSuccess={(newEntity) => {
                          entitiesQuery.refetch();
                          onChange(newEntity.id);
                        }}
                      />
                    </div>
                  )}
                </div>
                <SelectEntity
                  menuPosition="fixed"
                  options={entitiesList}
                  value={value}
                  onChange={onChange}
                  disabled={!!defaultValues?.entityId}
                />
                <FormError>{fieldState.error?.message}</FormError>
              </>
            )}
            rules={{ required: i18n.t("error.validation.required") }}
          />
          <Controller
            name="role"
            control={control}
            render={({ field: { onChange, value }, fieldState }) => (
              <>
                <FormLabel htmlFor="role">{i18n.t("label.role")}</FormLabel>
                <SelectRole
                  value={value}
                  onChange={onChange}
                  isApprover={isApprover}
                  enabled
                />
                <FormError>{fieldState.error?.message}</FormError>
              </>
            )}
            rules={{ required: i18n.t("error.validation.required") }}
          />
        </FormGroup>
        {mutation.error && <FormErrorList error={mutation.error} />}
      </form>
    </Dialog>
  );
};

export { EditUserDialog };
