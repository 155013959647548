import {
  createClient,
  DictPair,
  FailoverStrategy,
  IClient,
  KeyPair,
  newSignatureProvider,
  QueryObject,
  RawGtv,
} from "postchain-client";

import { blockchainRID, blockchainUrls, chainId } from "../../config";
import { getKeyPair } from "../../context/session";
import * as monitoring from "../../utils/monitoring";

export class BlockchainClient {
  private readonly nodeUrls: string[];

  private readonly chainId: number;

  private blockchainRid: string;

  private client: IClient | undefined;

  public constructor(
    nodeUrls: string[],
    blockchainRid = "",
    chainIdParam: number = 0
  ) {
    this.nodeUrls = nodeUrls;
    this.blockchainRid = blockchainRid;
    this.chainId = chainIdParam;
  }

  public async initialise() {
    if (!this.blockchainRid) {
      this.blockchainRid = await this.getBrid();
    }

    this.client = await createClient({
      nodeUrlPool: this.nodeUrls,
      blockchainRid: this.blockchainRid,
      failOverConfig: {
        strategy: FailoverStrategy.AbortOnError, // This will still try all node urls
        attemptsPerEndpoint: 1,
        unreachableDuration: 120000,
      },
    });
  }

  private getBrid = async (): Promise<string> => {
    const response = await fetch(`${this.nodeUrls[0]}brid/iid_${this.chainId}`);
    if (response.ok) {
      return response.text();
    }
    throw new Error(
      `Error resolving BRID for chainId ${this.chainId}, reason: ${response.statusText}`
    );
  };

  public async query<T extends RawGtv | RawGtv[]>(
    name: string,
    args?: DictPair
  ) {
    try {
      if (!this.client) {
        throw Error("No client");
      }
      const query: QueryObject<T> = {
        name,
        args,
      };
      const data = await this.client.query(query);
      return data;
    } catch (error) {
      monitoring.captureException(error, {
        extra: {
          name,
          args: JSON.stringify(args),
        },
      });

      throw error;
    }
  }

  public async renewSession(keyPair: KeyPair) {
    try {
      if (!this.client) {
        throw Error("No client");
      }
      const signatureProvider = newSignatureProvider(keyPair);
      await this.client.signAndSendUniqueTransaction(
        {
          operations: [
            {
              name: "auth.renew_session",
              args: [keyPair.pubKey],
            },
          ],
          signers: [signatureProvider.pubKey],
        },
        signatureProvider
      );
    } catch (error) {
      monitoring.captureException(error);
      throw error;
    }
  }

  public async sendTransaction(transaction: string) {
    const keyPair = getKeyPair();
    try {
      if (!this.client) {
        throw Error("No client");
      }
      if (!keyPair) {
        throw Error("No key pair");
      }
      const deserializedTx = Buffer.from(transaction, "hex");
      const signatureProvider = newSignatureProvider({
        privKey: keyPair.privKey,
      });
      const signedTx = await this.client.signTransaction(
        deserializedTx,
        signatureProvider
      );
      await this.client.sendTransaction(signedTx);
    } catch (error) {
      monitoring.captureException(error);
      throw error;
    }
  }

  public async getBlockHeight(): Promise<number> {
    if (!this.client) {
      throw Error("No client");
    }
    const latestBlock = await this.client.getLatestBlock();
    return latestBlock.height;
  }
}

const getBlockchainClient = async () => {
  const client = new BlockchainClient(blockchainUrls, blockchainRID, chainId);
  await client.initialise();
  return client;
};

export { getBlockchainClient };
export type { KeyPair };
