import { useQuery } from "@tanstack/react-query";

import { useBlockchainClient } from "../../context/blockchain";
import type { ApprovalRuleProposalResponse } from "../../types/models/approval-policy";
import { ApprovalRuleProposalResponseSchema } from "../../types/models/approval-policy";
import type { UserListResponse } from "../../types/models/users";
import * as monitoring from "../../utils/monitoring";
import { supportUserId } from "../../utils/user";
import type { IRequestError } from "..";
import { unmaskValues } from "../rest/company";
import type { UseQueryOptions } from "../types";
import { BlockchainClient } from "./client";

const getUnmaskedData = (
  data: UserListResponse,
  unmaskedValues: Record<string, string>
): UserListResponse =>
  data.map((d) => {
    const {
      user: { refId: maybeRefId },
    } = d;
    const refId =
      maybeRefId === supportUserId ? maybeRefId : unmaskedValues[maybeRefId];

    if (typeof refId !== "string") {
      monitoring.captureException(
        new TypeError("refId could not be unmasked"),
        { extra: { refId: maybeRefId, unmaskedValues } }
      );

      return d;
    }

    return { ...d, user: { ...d.user, refId } };
  });

const getUsers = async (
  blockchainClient: BlockchainClient,
  orgNumber: string
): Promise<UserListResponse> => {
  const data = await blockchainClient.query<UserListResponse>(
    "ledger_access.get_user_roles",
    { org_number: orgNumber }
  );

  const valuesToUnmask = data.reduce((prev, { user: { refId } }) => {
    if (refId === supportUserId) {
      return prev;
    }

    return { ...prev, [refId]: "" };
  }, {});

  const unmaskedValues = await unmaskValues(
    orgNumber,
    Object.keys(valuesToUnmask),
    true
  );

  return getUnmaskedData(data, unmaskedValues);
};

const getApprovalRuleProposal = async (
  blockchainClient: BlockchainClient,
  orgNumber: string
): Promise<ApprovalRuleProposalResponse> => {
  const data = await blockchainClient.query<ApprovalRuleProposalResponse>(
    "ledger.get_approval_rule_proposal",
    { org_number: orgNumber }
  );

  const result = ApprovalRuleProposalResponseSchema.safeParse(data);

  if (!result.success) {
    monitoring.captureException(
      TypeError("Error parsing response using ApprovalRuleProposalSchema"),
      { contexts: { data: data ?? undefined, result } }
    );

    return data;
  }

  return result.data;
};

const useUsersQuery = (
  orgNumber: string,
  options?: UseQueryOptions<
    UserListResponse,
    IRequestError,
    UserListResponse,
    string[]
  >
) => {
  const blockchainClient = useBlockchainClient();

  return useQuery<UserListResponse, IRequestError, UserListResponse, string[]>(
    ["users", orgNumber],
    () => getUsers(blockchainClient, orgNumber),
    {
      enabled: options?.enabled !== undefined ? options.enabled : !!orgNumber,
      ...options,
    }
  );
};

const useApprovalRuleProposalQuery = (
  orgNumber: string,
  options?: UseQueryOptions<
    ApprovalRuleProposalResponse,
    IRequestError,
    ApprovalRuleProposalResponse,
    string[]
  >
) => {
  const blockchainClient = useBlockchainClient();

  return useQuery<
    ApprovalRuleProposalResponse,
    IRequestError,
    ApprovalRuleProposalResponse,
    string[]
  >(
    ["ApprovalRuleProposal", orgNumber],
    () => getApprovalRuleProposal(blockchainClient, orgNumber),
    {
      enabled: options?.enabled !== undefined ? options.enabled : !!orgNumber,
      ...options,
    }
  );
};

export { getUnmaskedData, useApprovalRuleProposalQuery, useUsersQuery };
