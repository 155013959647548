import type React from "react";
import { forwardRef } from "react";

import { clsxm } from "../../../utils/tailwind";

type ListItemProps = React.HTMLAttributes<HTMLLIElement> & {
  interactive?: boolean;
  className?: string;
};

const ListItem = forwardRef(
  (
    { interactive, className, ...props }: ListItemProps,
    ref: React.Ref<HTMLLIElement>
  ) => (
    <li
      ref={ref}
      className={clsxm("tw-space-y-2 tw-px-6 tw-py-2", className, {
        "hover:tw-bg-gray-100": interactive,
      })}
      data-testid="list-item"
      {...props}
    />
  )
);

type ListProps = {
  header?: React.ReactNode;
  footer?: React.ReactNode;
  className?: string;
  "data-testid"?: string;
};

const List: React.FunctionComponent<React.PropsWithChildren<ListProps>> = ({
  header,
  footer,
  children,
  className,
  ...props
}) => (
  <div {...props}>
    {header}
    <ul className={`tw-flex tw-flex-col ${className}`}>{children}</ul>
    {footer}
  </div>
);

export { List, ListItem };
