import { ReactElement, ReactNode } from "react";

import { IconDescription } from "../../../components/IconDescription";
import type { LedgerVersion } from "../../../types/models/shares";
import {
  getFormattedDate,
  getFormattedLedgerVersion,
} from "../../../utils/date";

const EventLine = ({
  title,
  icon,
  description,
  event,
}: {
  title: string;
  icon: ReactNode;
  description: ReactElement | string;
  event: { date: LedgerVersion };
}) => {
  const formatedLedgerVersion = getFormattedLedgerVersion(event.date);
  const date = getFormattedDate(formatedLedgerVersion.date);

  return (
    <div className="tw-flex tw-items-center tw-gap-6 tw-text-left max-sm:tw-p-4 md:tw-gap-10">
      <div className="tw-text-sm tw-text-secondary">{date}</div>
      <IconDescription
        description={description}
        icon={icon}
        title={title}
        titleWeight="medium"
      />
    </div>
  );
};

export default EventLine;
