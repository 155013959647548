import type { UseMutationOptions } from "@tanstack/react-query";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useBlockchainClient } from "../../context/blockchain";
import { OptionsProgram } from "../../types/models/options";
import * as monitoring from "../../utils/monitoring";
import type { IRequestError } from "..";
import useClient, { URL } from "./client";
import { RawTxResponse, RawTxResponseSchema } from "./users";

const useCreateOptionsProgram = (
  orgNumber: string,
  options?: UseMutationOptions<unknown, IRequestError, OptionsProgram>
) => {
  const blockchainClient = useBlockchainClient();
  const queryClient = useQueryClient();
  const client = useClient({ hasAuth: true });

  return useMutation<unknown, IRequestError, OptionsProgram>(
    async (data) => {
      const response = await client<RawTxResponse>(
        `${URL.ADMIN}/Transaction/OptionsProgram/${orgNumber}`,
        { body: data, method: "PUT" }
      );

      const result = RawTxResponseSchema.safeParse(response);

      if (!result.success) {
        monitoring.captureException(result.error, {
          contexts: { response, result },
        });

        return blockchainClient.sendTransaction(response.rawTx);
      }

      return blockchainClient.sendTransaction(result.data.rawTx);
    },
    {
      ...options,
      onSuccess: (data, vars, context) => {
        queryClient.invalidateQueries({
          queryKey: ["options", orgNumber],
        });

        if (options?.onSuccess) {
          options.onSuccess(data, vars, context);
        }
      },
    }
  );
};

const useUpdateOptionsProgram = (
  orgNumber: string,
  currentProgram?: OptionsProgram,
  options?: UseMutationOptions<unknown, IRequestError, OptionsProgram>
) => {
  const blockchainClient = useBlockchainClient();
  const queryClient = useQueryClient();
  const client = useClient({ hasAuth: true });

  return useMutation<unknown, IRequestError, OptionsProgram>(
    async (data) => {
      const response = await client<RawTxResponse>(
        `${URL.ADMIN}/Transaction/OptionsProgram/${orgNumber}/${currentProgram?.id}`,
        { body: data, method: "PUT" }
      );

      const result = RawTxResponseSchema.safeParse(response);

      if (!result.success) {
        monitoring.captureException(result.error, {
          contexts: { response, result },
        });

        return blockchainClient.sendTransaction(response.rawTx);
      }

      if (!result.data.rawTx) {
        return null;
      }

      return blockchainClient.sendTransaction(result.data.rawTx);
    },
    {
      ...options,
      onSuccess: (data, vars, context) => {
        queryClient.invalidateQueries({
          queryKey: ["options", orgNumber],
        });

        if (options?.onSuccess) {
          options.onSuccess(data, vars, context);
        }
      },
    }
  );
};

const useDeleteOptionsProgram = (
  orgNumber?: string,
  currentProgram?: OptionsProgram,
  options?: UseMutationOptions<void, IRequestError, void>
) => {
  const blockchainClient = useBlockchainClient();
  const queryClient = useQueryClient();
  const client = useClient({ hasAuth: true });

  return useMutation<void, IRequestError, void>(
    async () => {
      if (!orgNumber) {
        throw new Error("Org number undefined");
      }

      const response = await client<RawTxResponse>(
        `${URL.ADMIN}/Transaction/OptionsProgram/${orgNumber}/${currentProgram?.id}`,
        { method: "DELETE" }
      );

      const result = RawTxResponseSchema.safeParse(response);

      if (!result.success) {
        monitoring.captureException(result.error, {
          contexts: { response, result },
        });

        return blockchainClient.sendTransaction(response.rawTx);
      }

      return blockchainClient.sendTransaction(result.data.rawTx);
    },
    {
      ...options,
      onSuccess: (data, vars, context) => {
        queryClient.invalidateQueries({
          queryKey: ["options", orgNumber],
        });

        if (options?.onSuccess) {
          options.onSuccess(data, vars, context);
        }
      },
    }
  );
};

export {
  useCreateOptionsProgram,
  useDeleteOptionsProgram,
  useUpdateOptionsProgram,
};
