import { z } from "zod";

const EnvironmentSchema = z
  .enum(["local", "dev", "staging", "production"])
  .optional()
  .default("local");
type Environment = z.infer<typeof EnvironmentSchema>;

const environment: Environment = import.meta.env.VITE_ENVIRONMENT || "local";
const apiUrl: string =
  import.meta.env.VITE_AUTH_URL || `http://${window.location.hostname}:5001`;
const adminUrl: string =
  import.meta.env.VITE_ADMIN_URL || `http://${window.location.hostname}:5002`;
const searchUrl: string =
  import.meta.env.VITE_SEARCH_URL || `http://${window.location.hostname}:5006`;
const blockchainUrls: string[] = import.meta.env.VITE_BLOCKCHAIN_URLS
  ? import.meta.env.VITE_BLOCKCHAIN_URLS.split(",")
  : [`http://${window.location.hostname}/`];
const blockchainRID: string | undefined = import.meta.env.VITE_BLOCKCHAINRID;
const chainId: number =
  parseInt(import.meta.env.VITE_BLOCKCHAIN_CHAIN_ID, 10) || 0;
const renewSessionsWhenBlocksLeft = 150;
const featureToggles: Record<string, string> =
  environment === "production"
    ? Object.freeze({})
    : Object.freeze({
        ENABLE_MOCK_AUTH: "ENABLE_MOCK_AUTH",
        ENABLE_MOCK_SIGN: "ENABLE_MOCK_SIGN",
        ENABLE_OWNERSHIP_FOR_TRUID: "ENABLE_OWNERSHIP_FOR_TRUID",
        ENABLE_AUDIT_LOG: "ENABLE_AUDIT_LOG",
      });

export type { Environment };
export {
  adminUrl,
  apiUrl,
  blockchainRID,
  blockchainUrls,
  chainId,
  environment,
  featureToggles,
  renewSessionsWhenBlocksLeft,
  searchUrl,
};
