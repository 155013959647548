import type { UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";

import { useBlockchainClient } from "../../context/blockchain";
import { useSession } from "../../context/session";
import type { IRequestError } from "..";
import { getAuthHeader } from "../auth";

const useUserConfirmedLedgerAsOfficial = (
  orgNumber: string = "",
  options: UseQueryOptions<unknown, IRequestError, 0 | 1, string[]> = {}
): UseQueryResult<0 | 1, IRequestError> => {
  const blockchainClient = useBlockchainClient();
  const session = useSession();
  const refId = session.user?.refId ?? "";
  const authHeader = getAuthHeader();

  return useQuery<unknown, IRequestError, 0 | 1, string[]>(
    ["userConfirmedLedgerAsOfficial", orgNumber, refId],
    () =>
      blockchainClient.query<0 | 1>(
        "ledger.user_has_confirmed_ledger_as_official",
        { auth: authHeader!, org_number: orgNumber }
      ),
    {
      enabled:
        authHeader !== undefined && options?.enabled !== undefined
          ? options.enabled
          : refId?.length > 0 && orgNumber.length > 0,
      ...options,
    }
  );
};

export { useUserConfirmedLedgerAsOfficial };
