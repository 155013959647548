import { z } from "zod";

import { Ti18nKey } from "../../i18n";

const RoleSchema = z.enum(["BoardMember", "Administrator", "Editor", "Viewer"]);

type Role = z.infer<typeof RoleSchema>;

type User = { id: string; refId: string; countryCode: string; name?: string };

type NewBoardMember = {
  refId?: string;
  countryCode: string;
  passportNumber?: string;
  birthDate?: string;
};

type NewUserRole = {
  entityId: string;
  role: Role;
};

type NewMultiUsersRole = {
  Role: string;
  UserIds: string[];
};

type UserRole = { user: User; role: Role };

type UserListResponse = UserRole[];

type SupportAccess = Role | "No access";

const roleLabels: Record<Role, Ti18nKey> = {
  Viewer: "role.viewer",
  Editor: "role.editor",
  Administrator: "role.administrator",
  BoardMember: "role.boardMember",
};

export type {
  NewBoardMember,
  NewMultiUsersRole,
  NewUserRole,
  Role,
  SupportAccess,
  User,
  UserListResponse,
  UserRole,
};
export { roleLabels, RoleSchema };
