import { Trans } from "react-i18next";

import { useChildEventsQuery } from "../../../../api/blockchain/events";
import type { CompanyInformation } from "../../../../types/models/administration";
import type { CompanyInvolvement } from "../../../../types/models/company";
import type { EntitiesMap } from "../../../../types/models/entities";
import type { ShareAllocationEvent } from "../../../../types/models/events";
import { formatNumber } from "../../../../utils/format";
import { calcSumWithinRange } from "../../../../utils/shares";
import { ListItem } from "../../../design-system/List";
import { Loading } from "../../../design-system/Loading";
import { getEntityWithFallback } from "../../EventsTable/EventsTable.utils";

export const ShareAllocationLine = ({
  event,
  entitiesMap,
}: {
  event: ShareAllocationEvent;
  entitiesMap: EntitiesMap;
}) => {
  const { recipient, shares } = event;

  return (
    <Trans
      i18nKey="events.allocation.content"
      values={{
        shares: formatNumber(calcSumWithinRange(shares)),
        start: formatNumber(shares.start),
        end: formatNumber(shares.end),
        recipient: getEntityWithFallback(entitiesMap, recipient).name,
        type: shares.type,
      }}
    />
  );
};

export const ShareAllocationEventList = ({
  parentEventId,
  currentCompany,
  entitiesMap,
}: {
  parentEventId: string;
  currentCompany: CompanyInvolvement | CompanyInformation;
  entitiesMap: EntitiesMap;
}) => {
  const childEventsQuery = useChildEventsQuery(
    currentCompany.orgNumber,
    parentEventId
  );
  if (childEventsQuery.isLoading) {
    return <Loading />;
  }
  if (!childEventsQuery.data) {
    return null;
  }

  return (
    <ul className="tw-flex tw-flex-col">
      {childEventsQuery.data.map((e) => (
        <ListItem key={e.shares.start} className="tw-py-0 tw-text-sm">
          <ShareAllocationLine event={e} entitiesMap={entitiesMap} />
        </ListItem>
      ))}
    </ul>
  );
};
