import type { TDraftShareBlock } from "../types/models/draft";
import type {
  Ledger,
  Range,
  Shareblock,
  Shareholder,
  ShareOwnership,
} from "../types/models/shares";

const shareOwnershipByType = (
  data: Pick<Shareblock, "type" | "start" | "end">[]
) =>
  Object.values(
    (data || []).reduce<Record<string, ShareOwnership>>(
      (prev, curr) => ({
        ...prev,
        [curr.type]: {
          type: curr.type,
          amount: (prev[curr.type]?.amount || 0) + calcSumWithinRange(curr),
        },
      }),
      {}
    )
  );

const isRangeValid = ({ start, end }: Range) => {
  const isInRange = start && end && end >= 1 && start >= 1 && end - start >= 0;

  return isInRange;
};

const validRanges = <T extends Range[]>(ranges: T) =>
  ranges.filter(isRangeValid) as T;

const sumRanges = (ranges: Range[]) =>
  validRanges(ranges).reduce(
    (prev, { start, end }) => prev + ((end || 0) - (start || 0)) + 1,
    0
  );

const calcSumWithinRange = (range: { start: number; end: number }) =>
  range.end - range.start + 1;

const hasOverlappingRanges = (ranges: Range[]) =>
  ranges.some((selected, indexToSearch) =>
    ranges.some((range, index) => {
      if (indexToSearch === index) {
        return false;
      }
      const rangeStart = range.start || 0;
      const rangeEnd = range.end || 0;
      const selectedStart = selected.start || 0;
      const selectedEnd = selected.end || 0;

      return (
        (rangeStart <= selectedStart && rangeEnd >= selectedStart) ||
        (rangeStart <= selectedEnd && rangeEnd >= selectedEnd) ||
        (rangeStart >= selectedStart && rangeEnd <= selectedEnd)
      );
    })
  );

const getActiveBlocks = (data: Shareblock[]) =>
  data.filter((b) => !b.cancelled);

const countTotalShares = (shareBlocks: Shareblock[]) =>
  shareBlocks.reduce((sum, block) => {
    const size = calcSumWithinRange(block);

    return sum + size;
  }, 0);

const countTotalVotes = (
  shareBlocks: Shareblock[],
  shareTypesMap: Record<string, { name: string; voteValue: number }>
) =>
  shareBlocks.reduce((sum, block) => {
    const voteValue = shareTypesMap[block.type]?.voteValue || 1;
    const size = calcSumWithinRange(block);

    return sum + size * voteValue;
  }, 0);

const generateNewBlocksProrata = (
  modifier: number,
  shareholders: Shareholder[],
  offset: number
) => {
  if (modifier <= 1) {
    return [];
  }
  const holdersWithCounts = shareholders.map(({ blocks, ...rest }) => {
    const sharesByType = blocks.reduce<Record<string, number>>(
      (prev, curr) => ({
        ...prev,
        [curr.type]: calcSumWithinRange(curr) + (prev[curr.type] || 0),
      }),
      {}
    );

    return { ...rest, sharesByType };
  });
  let previousEnd = offset;
  const blocks: TDraftShareBlock[] = [];
  holdersWithCounts.forEach((holder) => {
    const typeEntries = Object.entries(holder.sharesByType);
    typeEntries.forEach(([type, numberOfShares]) => {
      const newNumberOfShares =
        multiplyShares(modifier, numberOfShares) - numberOfShares;
      const start = previousEnd + 1;
      const end = previousEnd + newNumberOfShares;
      previousEnd = end;
      if (newNumberOfShares > 0) {
        blocks.push({ start, end, holderId: holder.id, type });
      }
    });
  });

  return blocks;
};

const getSplitMetrics = (modifier: number, ledgerData?: Ledger) => {
  const beforeShareCapital = ledgerData?.capital || 1;
  const beforeTotalShares = ledgerData?.shares.total || 1;
  const afterShareCapital = beforeShareCapital;
  const afterTotalShares = multiplyShares(modifier, beforeTotalShares);
  const beforeQuotaValue = beforeShareCapital / beforeTotalShares;
  const afterQuotaValue = afterShareCapital / afterTotalShares;

  return {
    shareCapital: { before: beforeShareCapital, after: afterShareCapital },
    quotaValue: { before: beforeQuotaValue, after: afterQuotaValue },
    numberOfShares: { before: beforeTotalShares, after: afterTotalShares },
  };
};

// This is to go around floating point calculations so we round up if the number computed is to close to the ceiling
// 0.001 is something to play with.
// For example: 1,05 * 2 000 000 = 2 009 999.9999999998  2 009 999.9999999998 + 0.001 = 2 010 000.0009999997
// For more detail: CAP-1075 on Linear
// Link:  https://linear.app/capchap/issue/CAP-1075/cant-complete-a-share-capital-increase-at-some-amounts-because-the

const multiplyShares = (modifier: number, shares: number) =>
  Math.floor(modifier * shares + 0.001);

export {
  calcSumWithinRange,
  countTotalShares,
  countTotalVotes,
  generateNewBlocksProrata,
  getActiveBlocks,
  getSplitMetrics,
  hasOverlappingRanges,
  isRangeValid,
  multiplyShares,
  shareOwnershipByType,
  sumRanges,
  validRanges,
};
