import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useUpdateShareClassesMutation } from "../../../api/rest/draft";
import { Button } from "../../../components/design-system/Button";
import { DistributionProgress } from "../../../components/design-system/DistributionProgress";
import {
  FormError,
  FormErrorList,
} from "../../../components/design-system/FormGroup";
import { ShareTypesList } from "../../../components/ShareTypes/List";
import type { CompanyInformation } from "../../../types/models/administration";
import type { CompanyInvolvement } from "../../../types/models/company";
import type { ShareType } from "../../../types/models/shares";
import { sumByKey } from "../../../utils/math";
import { draftName } from "./constants";
import { ShareClassesProvider } from "./ShareClassesProvider";
import type { FormChangeEvent, FormState } from "./types";

type ShareClassesProps = {
  currentCompany: CompanyInvolvement | CompanyInformation;
  existingShareClasses: ShareType[];
  initialData: FormState;
  onFormChange: FormChangeEvent;
  onSuccess: () => void;
  onCancel: () => void;
};

const formId = "onboarding-shareclasses-form";

const ShareClasses = ({
  currentCompany,
  onCancel,
  onSuccess,
  initialData,
  existingShareClasses,
  onFormChange,
}: ShareClassesProps) => {
  const i18n = useTranslation();
  const { control, handleSubmit, watch, formState, setError } = useForm<{
    value: FormState["shareClasses"];
  }>({ defaultValues: { value: initialData.shareClasses }, mode: "onChange" });
  const updateShareClassesMutation = useUpdateShareClassesMutation(
    currentCompany.orgNumber,
    draftName,
    {
      onSuccess: (_, formData) => {
        onFormChange({ shareClasses: formData });
        onSuccess();
      },
    }
  );

  const { value: formValues } = watch();
  const diff =
    sumByKey(formValues, "numberOfShares") -
    (initialData.shareCapital.totalShares || 0);

  const isValid = diff === 0;
  const onSubmit = (data: { value: FormState["shareClasses"] }) => {
    if (!isValid) {
      setError("value", {
        type: "manual",
        message: i18n.t("error.verification.shares.issue"),
      });
      return;
    }
    updateShareClassesMutation.mutate(data.value);
  };

  return (
    <ShareClassesProvider
      value={{ formValues, totalShares: initialData.shareCapital.totalShares }}
    >
      <form
        id={formId}
        onSubmit={handleSubmit(onSubmit)}
        className="tw-space-y-4"
      >
        <Controller
          control={control}
          name="value"
          render={({ field: { onChange, value } }) => (
            <>
              <ShareTypesList
                existingShareClasses={existingShareClasses}
                value={value}
                onChange={onChange}
              />
              <FormError>{formState.errors.value?.message}</FormError>
            </>
          )}
          rules={{ required: i18n.t("error.validation.required") }}
        />
        <DistributionProgress diff={diff} />
        {updateShareClassesMutation.error && (
          <FormErrorList error={updateShareClassesMutation.error} />
        )}
        <div className="tw-full tw-flex tw-justify-end tw-space-x-2 tw-py-4">
          <Button onClick={onCancel}>{i18n.t("label.back")}</Button>
          <Button
            isLoading={updateShareClassesMutation.isLoading}
            variant="solid"
            color="primary"
            type="submit"
            form={formId}
          >
            {i18n.t("label.continue")}
          </Button>
        </div>
      </form>
    </ShareClassesProvider>
  );
};

export default ShareClasses;
