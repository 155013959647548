import type { CompanyHoldings } from "@capchapdev/rell-api";
import type { UseQueryResult } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";

import { useBlockchainClient } from "../../context/blockchain";
import { useSession } from "../../context/session";
import type { IRequestError } from "..";
import { getAuthHeader } from "../auth";
import { HoldingHistory } from "./privateHolding";

const useCompanyHoldings = (
  version: "approved" | "latest" = "latest"
): UseQueryResult<CompanyHoldings[], IRequestError> => {
  const blockchainClient = useBlockchainClient();
  const session = useSession();
  const refId = session.user?.refId ?? "";
  const authHeader = getAuthHeader();

  return useQuery<
    CompanyHoldings[],
    IRequestError,
    CompanyHoldings[],
    string[]
  >(
    ["companyHoldings", version, refId],
    () =>
      blockchainClient.query<CompanyHoldings[]>(
        "ledger.company_holdings_for_version",
        {
          auth: authHeader!,
          version,
        }
      ),
    { enabled: refId.length > 0 && authHeader !== undefined }
  );
};

const useCompanyHistory = (
  orgNumber: string | undefined,
  holdingCompanyNr: string | undefined
): UseQueryResult<HoldingHistory[], IRequestError> => {
  const blockchainClient = useBlockchainClient();
  const session = useSession();
  const refId = session.user?.refId ?? "";
  const authHeader = getAuthHeader();

  return useQuery<HoldingHistory[], IRequestError, HoldingHistory[], string[]>(
    ["companyHoldingHistory", orgNumber!, holdingCompanyNr!, refId],
    () =>
      blockchainClient.query<HoldingHistory[]>(
        "ledger.company_holding_history",
        {
          auth: authHeader!,
          ledger_org_no: orgNumber!,
          company_org_no: holdingCompanyNr!,
        }
      ),
    {
      enabled:
        !!orgNumber &&
        !!holdingCompanyNr &&
        refId.length > 0 &&
        authHeader !== undefined,
    }
  );
};

export { useCompanyHistory, useCompanyHoldings };
