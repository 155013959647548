import React, { createContext, useContext, useEffect, useState } from "react";

import {
  BlockchainClient,
  getBlockchainClient,
} from "../api/blockchain/client";

const BlockchainClientContext = createContext<BlockchainClient | null>(null);

export const useBlockchainClient = () => {
  const context = useContext(BlockchainClientContext);
  if (context === null) {
    throw new Error(
      "useBlockchainClient must be used within a BlockchainClientProvider"
    );
  }
  return context;
};

const BlockchainClientProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const [blockchainClient, setBlockchainClient] =
    useState<BlockchainClient | null>(null);

  useEffect(() => {
    const getClient = async () => {
      const client = await getBlockchainClient();
      setBlockchainClient(client);
    };

    getClient();
  }, []);

  if (!blockchainClient) {
    return <></>;
  }

  return (
    <BlockchainClientContext.Provider value={blockchainClient}>
      {children}
    </BlockchainClientContext.Provider>
  );
};

export default BlockchainClientProvider;
