import { clsxm } from "../../../utils/tailwind";

type ListHeaderProps = {
  title: string | React.ReactNode;
  description?: string;
  actions?: React.ReactNode;
  actionsPosition?: "right" | "below";
  beforeTitle?: React.ReactNode;
  badge?: React.ReactNode;
  className?: string;
};

const ListHeader: React.FunctionComponent<ListHeaderProps> = ({
  title,
  description,
  actions,
  actionsPosition = "right",
  beforeTitle,
  badge,
  className,
}) => (
  <div
    className={clsxm(
      "tw-flex tw-items-center tw-justify-between tw-gap-4 tw-bg-neutral-50 tw-p-6",
      className,
      { "max-md:tw-flex-col": actionsPosition === "below" }
    )}
  >
    <div>
      <div className="tw-flex tw-items-center tw-gap-2 tw-text-sm sm:tw-gap-4">
        <div className="tw-flex tw-items-center tw-gap-2 sm:tw-gap-4">
          {beforeTitle}
          <h4 className="tw-text-semibold tw-text-sm tw-leading-tight">
            {title}
          </h4>
        </div>
        {badge}
      </div>
      {description !== undefined && description !== "" && (
        <div className="tw-text-sm tw-text-secondary">{description}</div>
      )}
    </div>
    <div
      className={clsxm({
        "tw-w-full md:tw-mt-0 md:tw-w-auto": actionsPosition === "below",
      })}
    >
      {actions}
    </div>
  </div>
);

export { ListHeader };
