import { useQuery } from "@tanstack/react-query";

import { getUserFromStorage } from "../../context/session";
import type {
  Language,
  UnregisteredCompanies,
} from "../../types/models/person";
import {
  LanguageSchema,
  UnregisteredCompaniesSchema,
} from "../../types/models/person";
import * as monitoring from "../../utils/monitoring";
import type { IRequestError } from "..";
import useClient, { NoContentSchema, URL } from "./client";

const getMyUnregisteredCompanies = async () => {
  const client = useClient({ hasAuth: true });

  const response = await client<UnregisteredCompanies>(
    `${URL.ADMIN}/Person/UnregisteredCompanies`
  );

  const result = UnregisteredCompaniesSchema.safeParse(response);

  if (!result.success) {
    monitoring.captureException(result.error, {
      contexts: { response, result },
    });

    return response;
  }

  return result.data;
};

const getMyLanguage = async () => {
  const client = useClient({ hasAuth: true });

  const response = await client<Language>(`${URL.ADMIN}/Person/Language`);

  const result = LanguageSchema.safeParse(response);

  if (!result.success) {
    monitoring.captureException(result.error, {
      contexts: { response, result },
    });

    return response;
  }

  return result.data;
};

const updateLanguage = async (language: string) => {
  const client = useClient({ hasAuth: true });

  const response = await client<undefined>(`${URL.ADMIN}/Entities/Language`, {
    body: { language },
    method: "PATCH",
  });

  const result = NoContentSchema.safeParse(response);

  if (!result.success) {
    monitoring.captureException(result.error, {
      contexts: { response, result },
    });
  }
};

const useMyUnregisteredCompaniesQuery = () =>
  useQuery<UnregisteredCompanies, IRequestError>(
    ["unregisteredCompanies", getUserFromStorage()?.refId ?? ""],
    getMyUnregisteredCompanies,
    { staleTime: Infinity, enabled: getUserFromStorage()?.refId !== undefined }
  );

const useMyLanguageQuery = () =>
  useQuery<Language, IRequestError>(
    ["language", getUserFromStorage()?.refId ?? ""],
    getMyLanguage,
    { staleTime: Infinity, enabled: getUserFromStorage()?.refId !== undefined }
  );

export { updateLanguage, useMyLanguageQuery, useMyUnregisteredCompaniesQuery };
