import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { useBlockchainClient } from "../../context/blockchain";
import {
  OptionsProgram,
  OptionsProgramResponse,
} from "../../types/models/options";
import { IRequestError } from "..";
import { AuthData, getAuthHeader } from "../auth";
import { BlockchainClient } from "./client";

const getOptionsPrograms = async (
  blockchainClient: BlockchainClient,
  authHeader: AuthData,
  orgNumber: string
) => {
  const programs = await blockchainClient.query<OptionsProgramResponse[]>(
    "options.get_option_programs_with_participants_for_org",
    { auth: authHeader, org_no: orgNumber }
  );
  return programs.map((x) => ({
    ...x,
    optionPrice: x.optionPrice && parseFloat(x.optionPrice),
    strikePrice: x.strikePrice && parseFloat(x.strikePrice),
  }));
};

const useOptionsProgramQuery = (
  orgNumber?: string,
  options?: UseQueryOptions<unknown, IRequestError, OptionsProgram[], string[]>
) => {
  const authHeader = getAuthHeader();
  const blockchainClient = useBlockchainClient();

  return useQuery(
    ["options", orgNumber || ""],
    () => getOptionsPrograms(blockchainClient, authHeader!, orgNumber || ""),
    { enabled: !!orgNumber && authHeader !== undefined, ...options }
  );
};

export { useOptionsProgramQuery };
