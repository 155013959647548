import { z } from "zod";

const OrganizationDtoSchema = z.object({
  id: z.string(),
  name: z.string(),
});

type OrganizationDto = z.infer<typeof OrganizationDtoSchema>;

const UnregisteredCompaniesSchema = z.object({
  unregisteredCompanies: z.array(OrganizationDtoSchema).refine(
    (arr) => {
      const ids = arr.map(({ id }) => id);

      return ids.length === new Set(ids).size;
    },
    { message: "All 'id' values in the array must be unique" }
  ),
});

type UnregisteredCompanies = z.infer<typeof UnregisteredCompaniesSchema>;

const LanguageSchema = z.object({
  language: z.string(),
});

type Language = z.infer<typeof LanguageSchema>;

export type { Language, OrganizationDto, UnregisteredCompanies };
export { LanguageSchema, OrganizationDtoSchema, UnregisteredCompaniesSchema };
