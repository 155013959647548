import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Entity } from "../../../types/models/entities";
import { EventType, ShareBlockHistory } from "../../../types/models/shares";
import { getFormattedDate } from "../../../utils/date";
import { Button } from "../../design-system/Button";
import { EntityItem } from "../../design-system/EntityItem";
import { LabelValue } from "../../design-system/LabelValue";
import { Section } from "../DetailedBlockRow";

type HistorySectionProps = {
  title: string;
  history?: ShareBlockHistory;
  entitiesMap: Record<string, Entity>;
  getEventTypeFormatted: (eventType: EventType) => string;
};

const HistorySection: React.FC<HistorySectionProps> = ({
  title,
  history,
  entitiesMap,
  getEventTypeFormatted,
}) => {
  const i18n = useTranslation();
  const [showAllPreviousHolders, setShowAllPreviousHolders] = useState(false);

  if (!history?.previousHolders || history.previousHolders.length === 0) {
    return null;
  }

  return (
    <Section
      className="tw-py-0"
      header={
        <p className="tw-mb-3 tw-font-normal tw-text-secondary">{title}</p>
      }
    >
      <div className="tw-flex tw-flex-col tw-gap-2">
        {history.previousHolders?.map((holder, index) => {
          if (!showAllPreviousHolders && index > 0) {
            return null;
          }

          const historicHolder =
            entitiesMap[holder.holder.id] ||
            Object.values(entitiesMap).find(
              (x) => x.refId === holder.holder.refId
            );
          if (!historicHolder) {
            return null;
          }

          return (
            <div
              key={`${holder.holder.id}-${holder.date}`}
              className="tw-grid tw-w-full tw-gap-2 tw-border-[1px] tw-p-4 print:tw-grid-cols-3 md:tw-grid-cols-3"
            >
              <EntityItem
                value={historicHolder}
                displayIcon={false}
                hasFlag={false}
              />
              <LabelValue
                label={getEventTypeFormatted(holder.eventType)}
                value={
                  <span>
                    <span className="tw-font-medium">
                      {getFormattedDate(holder.date)}
                    </span>
                  </span>
                }
              />
            </div>
          );
        })}

        {history.previousHolders?.length > 1 && (
          <Button
            type="button"
            className="tw-w-full tw-rounded tw-p-4 tw-text-sm"
            onClick={() => setShowAllPreviousHolders(!showAllPreviousHolders)}
          >
            {showAllPreviousHolders
              ? i18n.t("label.showLess")
              : `+ ${history.previousHolders.length - 1} ${i18n.t(
                  "label.more"
                )}`}
          </Button>
        )}
      </div>
    </Section>
  );
};

export { HistorySection };
