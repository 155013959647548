import { useQuery } from "@tanstack/react-query";

import { useBlockchainClient } from "../../context/blockchain";
import { useSession } from "../../context/session";
import type { CompanyInformation } from "../../types/models/administration";
import type { IRequestError } from "..";
import { getAuthHeader } from "../auth";

const useAdminCompaniesQuery = () => {
  const blockchainClient = useBlockchainClient();
  const authHeader = getAuthHeader();
  const { user } = useSession();

  return useQuery<unknown, IRequestError, CompanyInformation[], string[]>(
    ["adminCompanies", user?.refId ?? ""],
    () =>
      blockchainClient.query<CompanyInformation[]>("ledger.list_companies", {
        auth: authHeader!,
        skip: 0,
        take: Number.MAX_SAFE_INTEGER,
      }),
    {
      enabled:
        !!user?.refId &&
        user?.role === "Administrator" &&
        authHeader !== undefined,
    }
  );
};

export { useAdminCompaniesQuery };
