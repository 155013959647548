import type { Holding } from "@capchapdev/rell-api";
import type { UseQueryResult } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";

import { useBlockchainClient } from "../../context/blockchain";
import { useSession } from "../../context/session";
import type { IRequestError } from "..";
import { getAuthHeader } from "../auth";
import { HoldingHistory } from "./privateHolding";

type RepresentativeHolding = {
  type: string;
  holder: {
    id: string;
    refId: string;
    type: "Private" | "Company";
  };
  holding: Holding;
};

const useRepresentativeHolding = (
  version: "approved" | "latest" = "latest"
): UseQueryResult<RepresentativeHolding[], IRequestError> => {
  const blockchainClient = useBlockchainClient();
  const session = useSession();
  const refId = session.user?.refId ?? "";
  const authHeader = getAuthHeader();

  return useQuery<
    RepresentativeHolding[],
    IRequestError,
    RepresentativeHolding[],
    string[]
  >(
    ["representativeHolding", version, refId],
    () =>
      blockchainClient.query<RepresentativeHolding[]>(
        "ledger.representative_holdings",
        { auth: authHeader!, version }
      ),
    { enabled: refId.length > 0 && authHeader !== undefined }
  );
};

const useRepresentativeHistory = (
  orgNumber: string | undefined,
  holderId: string | undefined
): UseQueryResult<HoldingHistory[], IRequestError> => {
  const blockchainClient = useBlockchainClient();
  const session = useSession();
  const refId = session.user?.refId ?? "";
  const authHeader = getAuthHeader();

  return useQuery<HoldingHistory[], IRequestError, HoldingHistory[], string[]>(
    ["representativeHoldingHistory", orgNumber!, holderId!, refId],
    () =>
      blockchainClient.query<HoldingHistory[]>(
        "ledger.representative_holding_history",
        { auth: authHeader!, org_no: orgNumber!, holder_id: holderId! }
      ),
    {
      enabled:
        !!orgNumber &&
        !!holderId &&
        refId.length > 0 &&
        authHeader !== undefined,
    }
  );
};

export type { RepresentativeHolding };
export { useRepresentativeHistory, useRepresentativeHolding };
