import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import {
  useActiveApprovalRuleQuery,
  useApprovalInfoQuery,
} from "../../api/blockchain/company";
import { useParentEventsQuery } from "../../api/blockchain/events";
import {
  useApprovalRuleProposalQuery,
  useUsersQuery,
} from "../../api/blockchain/users";
import { useRollbackLedgerMutation } from "../../api/rest/company";
import { useEntitiesQuery } from "../../api/rest/entities";
import { useSession } from "../../context/session";
import { useCompanyEvents } from "../../hooks/useCompanyEvents";
import AddEvent from "../../pages/CompanyShares/AddEvent";
import type { CompanyInformation } from "../../types/models/administration";
import type { CompanyInvolvement } from "../../types/models/company";
import type { EntitiesMap } from "../../types/models/entities";
import type {
  LedgerRollbackPending,
  TParentEvent,
} from "../../types/models/events";
import type { LedgerVersion } from "../../types/models/shares";
import {
  getApprovalsCount,
  getApprovers,
  getRequiredTotalApprovalsCount,
} from "../../utils/approvalUtils";
import { isGreaterLedgerVersion } from "../../utils/date";
import {
  formatEventLabel,
  isBeforeOrSameVersion,
} from "../../utils/events-utils";
import { hasRequiredPermission } from "../../utils/permissions";
import { ApprovalBadge } from "../ApprovalBadge";
import { ApprovalStatus } from "../ApprovalStatus";
import { ApproveShares } from "../ApproveShares";
import { Button } from "../design-system/Button";
import { CollapseIcon, ExpandIcon } from "../design-system/icons";
import { List } from "../design-system/List";
import { ListHeader } from "../design-system/ListHeader";
import { Menu } from "../design-system/Menu";
import { TooltipV2 } from "../design-system/Tooltip/TooltipV2";
import { EventActionsBar } from "../EventActionsBar/EventActionsBar";
import { EventsTable } from "../EventList/EventsTable";
import { IconDescription } from "../IconDescription";
import { StartApproval } from "./Actions";
import { Reject } from "./Actions/Reject";
import {
  getVersionToApprove,
  validateApprovalPermissions,
} from "./History.utils";
import { ApprovalAction } from "./types";

const useExpandEvents = (
  events: TParentEvent[],
  currentCompany?: CompanyInvolvement
) => {
  const { draftEvents, pendingEvents, pendingRollbackEvents, approvedEvents } =
    useCompanyEvents(currentCompany?.orgNumber);
  const finalApprovedEvents = approvedEvents.filter(
    (e) => !pendingRollbackEvents.includes(e)
  );

  const getEventsWithState = (
    filteredEvents: TParentEvent[],
    isExpanded: boolean
  ): Record<string, boolean> =>
    Object.fromEntries(
      Object.values(filteredEvents).map(({ id }) => [id, isExpanded])
    );

  const allEventsCollapsed = getEventsWithState(events, false);
  const allEventsExpanded = getEventsWithState(events, true);

  const [expandedEvents, setExpandedEvents] =
    useState<ReturnType<typeof getEventsWithState>>(allEventsCollapsed);

  const setEventsExpandedState = (eventIds: string[], expanded: boolean) => {
    const updatedExpandedEvents = { ...expandedEvents };
    eventIds.forEach((id) => {
      updatedExpandedEvents[id] = expanded;
    });
    setExpandedEvents(updatedExpandedEvents);
  };

  const collapseAll = () => {
    setExpandedEvents(allEventsCollapsed);
  };
  const expandAll = () => {
    setExpandedEvents(allEventsExpanded);
  };

  const toggleDraftExpansion = (expand: boolean) => {
    setEventsExpandedState(
      draftEvents.map((event) => event.id),
      expand
    );
  };

  const togglePendingExpansion = (expand: boolean) => {
    const pendingEventIds = [...pendingEvents, ...pendingRollbackEvents].map(
      (event) => event.id
    );
    setEventsExpandedState(pendingEventIds, expand);
  };

  const toggleApprovedExpansion = (expand: boolean) => {
    const approvedEventIds = finalApprovedEvents.map((event) => event.id);
    setEventsExpandedState(approvedEventIds, expand);
  };

  const togglePendingRollbackExpansion = (expand: boolean) => {
    const pendingRollbackEventIds = pendingRollbackEvents.map(
      (event) => event.id
    );
    setEventsExpandedState(pendingRollbackEventIds, expand);
  };

  return {
    expandedEvents,
    setExpandedEvents,
    collapseAll,
    expandAll,
    toggleDraftExpansion,
    togglePendingExpansion,
    toggleApprovedExpansion,
    togglePendingRollbackExpansion,
  };
};

const History = ({
  currentCompany,
  currentVersion,
  entitiesMap,
}: {
  currentCompany: CompanyInvolvement | CompanyInformation;
  currentVersion: LedgerVersion;
  entitiesMap: EntitiesMap;
}) => {
  const [isDraftEventsExpanded, setIsDraftEventsExpanded] = useState(false);
  const [isPendingEventsExpanded, setIsPendingEventsExpanded] = useState(false);
  const [isApprovedEventsExpanded, setIsApprovedEventsExpanded] =
    useState(false);
  const [isPendingRollbackEventsExpanded, setIsPendingRollbackEventsExpanded] =
    useState(false);
  const [currentAction, setCurrentAction] = useState<ApprovalAction>();
  const [isStartApprovalLoading, setStartApprovalLoading] = useState(false);

  const eventsQuery = useParentEventsQuery({
    orgNumber: currentCompany.orgNumber,
    offset: 0,
    limit: Number.MAX_SAFE_INTEGER,
    onSuccess: (data: TParentEvent[]) => checkForRollback(data),
  });
  const i18n = useTranslation();
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const events = useMemo(
    () => (eventsQuery.isSuccess ? eventsQuery.data.data : []),
    [eventsQuery]
  );

  const filteredEvents = useMemo(() => {
    return events.filter(
      (e) =>
        (currentVersion
          ? !isGreaterLedgerVersion(e.date, currentVersion)
          : true) &&
        e.type !== "LedgerApproval" &&
        e.type !== "LedgerApprovalInitialized" &&
        e.type !== "LedgerApprovalRejected" &&
        e.type !== "LedgerRollbackPending" &&
        e.type !== "LedgerRollback" &&
        e.type !== "LedgerRollbackRejected"
    );
  }, [events, currentVersion]);

  const {
    expandedEvents,
    setExpandedEvents,
    collapseAll,
    expandAll,
    toggleDraftExpansion,
    togglePendingExpansion,
    toggleApprovedExpansion,
    togglePendingRollbackExpansion,
  } = useExpandEvents(filteredEvents);

  const { draftEvents, pendingEvents, pendingRollbackEvents, approvedEvents } =
    useCompanyEvents(currentCompany.orgNumber);
  const finalApprovedEvents = approvedEvents.filter(
    (e) => !pendingRollbackEvents.includes(e)
  );

  const handleToggleDraftEvents = () => {
    toggleDraftExpansion(!isDraftEventsExpanded);
    setIsDraftEventsExpanded(!isDraftEventsExpanded);
  };

  const handleTogglePendingEvents = () => {
    togglePendingExpansion(!isPendingEventsExpanded);
    setIsPendingEventsExpanded(!isPendingEventsExpanded);
  };

  const handleToggleApprovedEvents = () => {
    toggleApprovedExpansion(!isApprovedEventsExpanded);
    setIsApprovedEventsExpanded(!isApprovedEventsExpanded);
  };

  const handleTogglePendingRollbackEvents = () => {
    togglePendingRollbackExpansion(!isPendingRollbackEventsExpanded);
    setIsPendingRollbackEventsExpanded(!isPendingRollbackEventsExpanded);
  };
  const handleClose = () => {
    setCurrentAction(undefined);
  };

  const versionToApprove = useMemo(() => getVersionToApprove(events), [events]);

  const versionToApproveApprovalInfo = useApprovalInfoQuery(
    currentCompany.orgNumber,
    versionToApprove?.value
  );

  const activeApprovalRuleQuery = useActiveApprovalRuleQuery(
    currentCompany.orgNumber
  );

  const pendingApprovalRuleProposalQuery = useApprovalRuleProposalQuery(
    currentCompany.orgNumber
  );

  const isApprovalPolicyPending =
    pendingApprovalRuleProposalQuery.isSuccess &&
    !!pendingApprovalRuleProposalQuery.data;

  const { user } = useSession();

  const userCanApprove =
    user &&
    activeApprovalRuleQuery.isSuccess &&
    activeApprovalRuleQuery.data !== null &&
    versionToApproveApprovalInfo.isSuccess &&
    versionToApproveApprovalInfo.data !== null &&
    validateApprovalPermissions(
      versionToApproveApprovalInfo.data,
      activeApprovalRuleQuery.data,
      currentCompany,
      user
    );

  const userCanInitiateApproval =
    hasRequiredPermission("Editor", currentCompany, user) || userCanApprove;

  const hasTheBoardSelectedAnApprovalPolicy =
    versionToApproveApprovalInfo.isSuccess &&
    versionToApproveApprovalInfo.data !== null &&
    versionToApproveApprovalInfo.data.rule !== "None";

  const isLedgerPendingApprovalByUser =
    user !== undefined &&
    versionToApproveApprovalInfo.isSuccess &&
    versionToApproveApprovalInfo.data !== null &&
    versionToApproveApprovalInfo.data.rule !== "None" &&
    versionToApproveApprovalInfo.data.status === "Pending" &&
    versionToApproveApprovalInfo.data.pendingApprovalBy.some(
      ({ id }) => id === user.id
    );
  const isUserApprover =
    user !== undefined &&
    versionToApproveApprovalInfo.isSuccess &&
    versionToApproveApprovalInfo.data !== null &&
    versionToApproveApprovalInfo.data.rule !== "None" &&
    versionToApproveApprovalInfo.data.status === "Pending" &&
    (versionToApproveApprovalInfo.data.approvedBy.some(
      ({ id }) => id === user.id
    ) ||
      versionToApproveApprovalInfo.data.pendingApprovalBy.some(
        ({ id }) => id === user.id
      ));

  const rollbackPending = events.find(
    (e) => e.type === "LedgerRollbackPending"
  ) as LedgerRollbackPending;
  const rollbackTo =
    rollbackPending &&
    events.find(
      (e) =>
        isBeforeOrSameVersion(e.date, rollbackPending.rollback_date) &&
        ![
          "LedgerRollback",
          "LedgerPolicyApproval",
          "LedgerApprovalInitialized",
          "LedgerApproval",
          "LedgerRollbackRejected",
          "LedgerRollbackPending",
        ].includes(e.type)
    );
  const hasPendingRollbackEvents = pendingRollbackEvents.length > 0;

  const hasDraftEvents = draftEvents.length > 0;
  const hasPendingEvents = pendingEvents.length > 0;
  const hasApprovedEvents = finalApprovedEvents.length > 0;

  const shouldShowInitiateApprovalButton =
    hasDraftEvents &&
    userCanInitiateApproval &&
    !(hasPendingEvents || hasPendingRollbackEvents);
  const shouldDisableInitiateApprovalButton =
    !hasTheBoardSelectedAnApprovalPolicy || isApprovalPolicyPending;

  const shouldShowApproveButton =
    hasTheBoardSelectedAnApprovalPolicy &&
    !shouldShowInitiateApprovalButton &&
    (hasPendingEvents || hasPendingRollbackEvents) &&
    userCanApprove &&
    !isStartApprovalLoading;
  const shouldDisableApproveButton = !isLedgerPendingApprovalByUser;

  const shouldShowRejectButton =
    hasTheBoardSelectedAnApprovalPolicy && shouldShowApproveButton;

  const entitiesQuery = useEntitiesQuery(currentCompany.orgNumber);
  const usersQuery = useUsersQuery(currentCompany.orgNumber);

  const rollbackLedgerMutation = useRollbackLedgerMutation(
    currentCompany.orgNumber
  );

  const checkForRollback = (data: TParentEvent[]) => {
    const latestApproval = data.find((e) => e.type === "LedgerApproval");
    const latestRollbackPending = data.find(
      (e) => e.type === "LedgerRollbackPending"
    );
    const rollbackPendingEvent = latestRollbackPending as LedgerRollbackPending;
    if (
      rollbackPendingEvent &&
      latestApproval?.date === rollbackPendingEvent?.date
    ) {
      rollbackLedgerMutation.mutate(rollbackPendingEvent.rollback_date);
    }
  };

  const eventLists: { id: string; EventList: React.ReactNode }[] = [
    {
      id: "draftEvents",
      EventList: hasDraftEvents && (
        <List
          header={
            <ListHeader
              className="tw-bg-transparent tw-pb-2 tw-pt-4 max-sm:tw-px-0"
              beforeTitle={
                <div
                  className="tw-h-3 tw-w-3 tw-rounded-sm tw-border-2 tw-border-solid tw-border-neutral-300 tw-bg-neutral-100"
                  data-testid="list-header-status-indicator"
                />
              }
              title={i18n.t("events.title.draft")}
              actions={
                <ExpandIcon
                  className="tw-my-2 tw-ml-4 tw-h-5 tw-w-5 sm:tw-hidden"
                  onClick={handleToggleDraftEvents}
                />
              }
            />
          }
        >
          <div data-testid="draft-events">
            <EventsTable
              events={draftEvents}
              currentCompany={currentCompany}
              entitiesMap={entitiesMap}
              expandedEvents={expandedEvents}
              setExpandedEvents={setExpandedEvents}
            />
          </div>
        </List>
      ),
    },
    {
      id: "pendingEvents",
      EventList: versionToApproveApprovalInfo.isSuccess &&
        versionToApproveApprovalInfo.data !== null &&
        versionToApproveApprovalInfo.data.rule !== "None" &&
        hasPendingEvents &&
        entitiesQuery.isSuccess &&
        usersQuery.isSuccess &&
        user !== undefined && (
          <List
            header={
              <ListHeader
                className="tw-h-16 tw-bg-transparent tw-pb-2 tw-pt-4 max-sm:tw-px-0"
                beforeTitle={
                  <div
                    className="tw-h-3 tw-w-3 tw-rounded-sm tw-border-2 tw-border-solid tw-border-orange-300 tw-bg-orange-100"
                    data-testid="list-header-status-indicator"
                  />
                }
                badge={
                  <>
                    <ApprovalBadge
                      approved={
                        getApprovalsCount(versionToApproveApprovalInfo.data) ||
                        0
                      }
                      approvers={getApprovers(
                        versionToApproveApprovalInfo.data,
                        entitiesQuery.data,
                        usersQuery.data,
                        user.id
                      )}
                      total={
                        getRequiredTotalApprovalsCount(
                          versionToApproveApprovalInfo.data
                        ) || 0
                      }
                    />
                    <div className="max-sm:tw-hidden">
                      {isUserApprover && (
                        <ApprovalStatus
                          isPendingYourApproval={isLedgerPendingApprovalByUser}
                        />
                      )}
                    </div>
                  </>
                }
                actions={
                  <ExpandIcon
                    className="tw-my-2 tw-ml-4 tw-h-5 tw-w-5 sm:tw-hidden"
                    onClick={handleTogglePendingEvents}
                  />
                }
                title={i18n.t("events.title.pending")}
              />
            }
          >
            <div data-testid="pending-events">
              <EventsTable
                events={pendingEvents}
                currentCompany={currentCompany}
                entitiesMap={entitiesMap}
                expandedEvents={expandedEvents}
                setExpandedEvents={setExpandedEvents}
              />
            </div>
          </List>
        ),
    },
    {
      id: "pendingRollbackEvents",
      EventList: hasPendingRollbackEvents && (
        <List
          header={
            versionToApproveApprovalInfo.isSuccess &&
            versionToApproveApprovalInfo.data !== null &&
            versionToApproveApprovalInfo.data.rule !== "None" &&
            entitiesQuery.isSuccess &&
            usersQuery.isSuccess &&
            user !== undefined ? (
              <ListHeader
                beforeTitle={
                  <div
                    className="tw-h-3 tw-w-3 tw-rounded-sm tw-border-2 tw-border-solid tw-border-orange-300 tw-bg-orange-100"
                    data-testid="list-header-status-indicator"
                  />
                }
                badge={
                  <>
                    <ApprovalBadge
                      approved={
                        getApprovalsCount(versionToApproveApprovalInfo.data) ||
                        0
                      }
                      approvers={getApprovers(
                        versionToApproveApprovalInfo.data,
                        entitiesQuery.data,
                        usersQuery.data,
                        user.id
                      )}
                      total={
                        getRequiredTotalApprovalsCount(
                          versionToApproveApprovalInfo.data
                        ) || 0
                      }
                    />
                    <div className="max-sm:tw-hidden">
                      {isUserApprover && (
                        <ApprovalStatus
                          isPendingYourApproval={isLedgerPendingApprovalByUser}
                        />
                      )}
                    </div>
                  </>
                }
                title={i18n.t("events.title.pendingRollback")}
                className="tw-h-16 tw-bg-transparent tw-pb-2 tw-pt-4 max-sm:tw-px-0"
                actions={
                  <ExpandIcon
                    className="tw-my-2 tw-ml-4 tw-h-5 tw-w-5 sm:tw-hidden"
                    onClick={handleTogglePendingRollbackEvents}
                  />
                }
              />
            ) : (
              <ListHeader
                beforeTitle={
                  <div
                    className="tw-h-3 tw-w-3 tw-rounded-sm tw-border-2 tw-border-solid tw-border-orange-300 tw-bg-orange-100"
                    data-testid="list-header-status-indicator"
                  />
                }
                title={i18n.t("events.title.pendingRollback")}
              />
            )
          }
        >
          <div data-testid="pending-rollback-events">
            <EventsTable
              events={pendingRollbackEvents}
              currentCompany={currentCompany}
              entitiesMap={entitiesMap}
              expandedEvents={expandedEvents}
              setExpandedEvents={setExpandedEvents}
            />
          </div>
        </List>
      ),
    },
    {
      id: "approvedEvents",
      EventList: hasApprovedEvents && (
        <List
          header={
            <ListHeader
              className="tw-h-16 tw-bg-transparent tw-pb-2 tw-pt-4 max-sm:tw-px-0"
              beforeTitle={
                <div
                  className="tw-h-3 tw-w-3 tw-rounded-sm tw-border-2 tw-border-solid tw-border-green-300 tw-bg-green-100"
                  data-testid="list-header-status-indicator"
                />
              }
              title={i18n.t("events.title.approved")}
              actions={
                <ExpandIcon
                  className="tw-my-2 tw-ml-4 tw-h-5 tw-w-5 sm:tw-hidden"
                  onClick={handleToggleApprovedEvents}
                />
              }
            />
          }
        >
          <div data-testid="approved-events">
            <EventsTable
              events={finalApprovedEvents}
              currentCompany={currentCompany}
              entitiesMap={entitiesMap}
              expandedEvents={expandedEvents}
              setExpandedEvents={setExpandedEvents}
            />
          </div>
        </List>
      ),
    },
  ];

  return (
    <div className="tw-space-y-4">
      <div className="tw-flex tw-flex-col tw-content-start tw-gap-2 print:tw-hidden max-sm:tw-hidden md:tw-flex-row">
        <div className="tw-flex tw-flex-1 tw-items-center tw-justify-start tw-gap-2 max-md:tw-hidden">
          <Menu>
            <Menu.Button>{i18n.t("label.view")}</Menu.Button>
            <Menu.Items align="bottomLeft" className="tw-divide-y">
              <div>
                <Menu.Item closeOnClick={false} onClick={expandAll}>
                  <IconDescription
                    icon={<ExpandIcon className="tw-h-5 tw-w-5" />}
                    title={
                      <span className="tw-text-base">
                        {i18n.t("label.expandAll")}
                      </span>
                    }
                  />
                </Menu.Item>
                <Menu.Item closeOnClick={false} onClick={collapseAll}>
                  <IconDescription
                    icon={<CollapseIcon className="tw-h-5 tw-w-5" />}
                    title={
                      <span className="tw-text-base">
                        {i18n.t("label.collapseAll")}
                      </span>
                    }
                  />
                </Menu.Item>
              </div>
            </Menu.Items>
          </Menu>
        </div>
        {currentAction === "approve" && versionToApprove && (
          <ApproveShares
            onClose={handleClose}
            isRollback={!!rollbackPending}
            versionLabel={rollbackTo && formatEventLabel(rollbackTo)}
            version={versionToApprove}
            currentCompany={currentCompany}
            onSuccess={() => {
              handleClose();
              eventsQuery.refetch();
            }}
          />
        )}
        <div>
          {hasRequiredPermission("Editor", currentCompany, user) && (
            <div className="tw-flex tw-items-center tw-justify-stretch tw-gap-2 max-md:tw-grid">
              <AddEvent align="bottomRight" disabled={!!rollbackPending} />

              {shouldShowRejectButton && (
                <Reject
                  orgNumber={currentCompany.orgNumber}
                  currentVersion={currentVersion}
                />
              )}
              {shouldShowInitiateApprovalButton && (
                <StartApproval
                  orgNumber={currentCompany.orgNumber}
                  currentVersion={currentVersion}
                  setIsLoading={setStartApprovalLoading}
                  disabled={
                    shouldDisableInitiateApprovalButton ||
                    isApprovalPolicyPending
                  }
                  placement="bottom-end"
                  showPanel={!isTabletOrMobileDevice}
                  setCurrentAction={setCurrentAction}
                />
              )}
              {shouldShowApproveButton && (
                <TooltipV2
                  content={
                    shouldDisableApproveButton
                      ? i18n.t("approvalPolicy.youHaveAlreadyApproved")
                      : null
                  }
                >
                  <Button
                    className="tw-w-full"
                    color="primary"
                    disabled={shouldDisableApproveButton}
                    variant="solid"
                    onClick={() => {
                      setCurrentAction("approve");
                    }}
                  >
                    {i18n.t(
                      rollbackPending
                        ? "label.approveRollback"
                        : "label.approvePending"
                    )}
                  </Button>
                </TooltipV2>
              )}
            </div>
          )}
        </div>
      </div>
      <div>
        <div className="max-sm:tw-pb-32">
          {eventLists.map(
            ({ id, EventList }) => EventList && <div key={id}>{EventList}</div>
          )}
        </div>
        <EventActionsBar
          shouldShowInitiateApprovalButton={shouldShowInitiateApprovalButton}
          shouldShowApproveButton={shouldShowApproveButton}
          shouldShowRejectButton={shouldShowRejectButton}
          shouldDisableInitiateApprovalButton={
            shouldDisableInitiateApprovalButton
          }
          shouldDisableApproveButton={shouldDisableApproveButton}
          setStartApprovalLoading={setStartApprovalLoading}
          isApprovalPolicyPending={isApprovalPolicyPending}
          rollbackPending={rollbackPending}
          setCurrentAction={setCurrentAction}
          currentCompany={currentCompany}
          currentVersion={currentVersion}
          user={user}
        />
      </div>
    </div>
  );
};

export { History };
