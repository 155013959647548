import { UseFormRegister } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";

import { Entity } from "../../types/models/entities";
import { Shareblock, ShareRange } from "../../types/models/shares";
import { calcSumWithinRange } from "../../utils/shares";
import { clsxm } from "../../utils/tailwind";
import { Button } from "../design-system/Button";
import { FormError, FormGroup, FormLabel } from "../design-system/FormGroup";
import { TrashIcon } from "../design-system/icons";
import { Input } from "../design-system/Input";
import { List, ListItem } from "../design-system/List";
import { ListHeader } from "../design-system/ListHeader";
import { NoData } from "../NoData";

export type ShareRangeErrors = {
  [index: number]: ShareRangeError;
};
type ShareRangeError = {
  start?: { message?: string };
  end?: { message?: string };
};

const Row = ({
  register,
  fieldKey,
  error,
  index,
  value,
  canDelete,
  onChange,
  onDelete,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>;
  fieldKey: string;
  error: ShareRangeError | undefined;
  index: number;
  value: ShareRange;
  canDelete: boolean;
  onChange: (newValue: ShareRange) => void;
  onDelete: () => void;
}) => {
  const i18n = useTranslation();

  return (
    <div className="tw-flex tw-gap-4">
      <div className="tw-grid tw-flex-1 tw-auto-cols-fr tw-gap-4 md:tw-grid-flow-col">
        <FormGroup>
          <FormLabel htmlFor={`${fieldKey}.${index}.start`}>
            {i18n.t("label.from")}
          </FormLabel>
          <Input
            id={`${fieldKey}.${index}.start`}
            value={value.start?.toString() ?? ""}
            {...register(`${fieldKey}.${index}.start`, {
              required: i18n.t("error.validation.required"),
              min: {
                value: 1,
                message: i18n.t("error.validation.range.min", {
                  min: 1,
                }),
              },
              max: {
                value: value?.end || Number.MAX_SAFE_INTEGER,
                message: i18n.t("error.validation.shareRange.from"),
              },
            })}
            onChange={(e) => {
              const { start: _, ...rest } = value;
              if (e.target.value === "") {
                onChange({ ...rest, start: null });
              } else if (Number.isNaN(e.target.valueAsNumber)) {
                onChange(value);
              } else {
                onChange({ ...rest, start: e.target.valueAsNumber });
              }
            }}
            type="number"
            step={1}
            min={1}
          />
          <FormError>{error && error?.start?.message}</FormError>
        </FormGroup>
        <FormGroup>
          <FormLabel htmlFor={`${fieldKey}.${index}.end`}>
            {i18n.t("label.to")}
          </FormLabel>
          <Input
            id={`${fieldKey}.${index}.end`}
            {...register(`${fieldKey}.${index}.end`, {
              required: i18n.t("error.validation.required"),
              min: {
                value: value?.start || 1,
                message: value.start
                  ? i18n.t("error.validation.shareRange.to")
                  : i18n.t("error.validation.range.min", {
                      min: 1,
                    }),
              },
            })}
            value={value.end?.toString() ?? ""}
            onChange={(e) => {
              const { end: _, ...rest } = value;
              if (e.target.value === "") {
                onChange({ ...rest, end: null });
              } else if (Number.isNaN(e.target.valueAsNumber)) {
                onChange(value);
              } else {
                onChange({ ...rest, end: e.target.valueAsNumber });
              }
            }}
            type="number"
            step={1}
            min={1}
          />
          <FormError>{error && error?.end?.message}</FormError>
        </FormGroup>
      </div>
      <div className="tw-flex tw-items-center md:tw-items-end">
        <Button
          variant="clean"
          size="md"
          onClick={onDelete}
          disabled={!canDelete}
        >
          <TrashIcon className="tw-h-6 tw-w-6" />
        </Button>
      </div>
    </div>
  );
};

const sumOfSharesWithinRange: (
  range: Pick<ShareRange, "end" | "start">
) => number | undefined = ({ start, end }) =>
  typeof start === "number" && typeof end === "number"
    ? calcSumWithinRange({ start, end })
    : undefined;

const SelectMultiRange = ({
  existingShareBlocks,
  entities,
  value,
  onChange,
  register,
  fieldKey,
  errors,
}: {
  existingShareBlocks: Shareblock[];
  entities: Entity[];
  value: ShareRange[];
  onChange: (newValue: ShareRange[]) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>;
  fieldKey: string;
  errors: ShareRangeErrors;
}) => {
  const i18n = useTranslation();
  const handleAdd = () => {
    onChange([...value, { type: new Date().toISOString() }]);
  };

  return (
    <List
      header={<ListHeader title={i18n.t("label.shareRanges")} />}
      footer={
        <div className="tw-p-4">
          <Button onClick={handleAdd}>{i18n.t("label.addRange")}</Button>
        </div>
      }
    >
      {value.map((range, index) => {
        const sumOfShares = sumOfSharesWithinRange(range);
        const canDelete = value.length > 1;
        const error = errors[index];
        const matchingBlock = existingShareBlocks.find(
          (x) =>
            range.start &&
            range.end &&
            x.start <= range.start &&
            x.end >= range.end
        );
        const matchingEntity =
          matchingBlock &&
          entities.find(
            (x) =>
              x.id === matchingBlock.holder.id ||
              x.refId === matchingBlock.holder.refId
          );

        return (
          <ListItem key={range.type}>
            <Row
              register={register}
              fieldKey={fieldKey}
              error={error}
              index={index}
              value={range}
              canDelete={canDelete}
              onChange={(newValue) => {
                onChange(value.map((v, i) => (i === index ? newValue : v)));
              }}
              onDelete={() => {
                onChange(value.filter((_, i) => i !== index));
              }}
            />
            <div
              className={clsxm(
                "tw-block tw-text-sm tw-font-medium tw-text-gray-700",
                {
                  "tw-hidden": sumOfShares === undefined,
                }
              )}
              data-testid="select-multi-range-total"
            >
              {sumOfShares === undefined ? null : sumOfShares > 0 ? (
                matchingEntity && matchingBlock ? (
                  matchingBlock.cancelled ? (
                    <FormError>
                      {i18n.t("error.verification.shares.cancelled")}
                    </FormError>
                  ) : (
                    <Trans
                      i18nKey="label.currentOwner"
                      values={{
                        total: sumOfShares,
                        start: matchingBlock.start,
                        end: matchingBlock.end,
                        name: matchingEntity.name,
                      }}
                    />
                  )
                ) : (
                  <>
                    <FormError>
                      {i18n.t("error.validation.range.notFound")}
                    </FormError>
                    {`${i18n.t("label.total")} ${sumOfShares}`}
                  </>
                )
              ) : (
                <FormError>{`${i18n.t(
                  "label.total"
                )} ${sumOfShares}`}</FormError>
              )}
            </div>
          </ListItem>
        );
      })}
      {value.length === 0 && <NoData />}
    </List>
  );
};

export { SelectMultiRange };
