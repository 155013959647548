import { Book, Books, ChartPieSlice } from "@phosphor-icons/react";
import type React from "react";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";

import { APP_ROUTE } from "../../routes/constants";
import type { CompanyInformation } from "../../types/models/administration";
import type { CompanyInvolvement } from "../../types/models/company";
import { clsxm } from "../../utils/tailwind";
import { CrossIcon, LogoIcon } from "../design-system/icons";
import type { NavItem } from "../NewNavigation";

type SideMenuProps = {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  companyLinks?: NavItem[];
  currentCompany?: CompanyInvolvement | CompanyInformation;
};

const SideMenu: React.FC<SideMenuProps> = ({
  isOpen,
  setIsOpen,
  companyLinks,
  currentCompany,
}) => {
  const i18n = useTranslation();
  const sideMenuRef = useRef<HTMLInputElement>(null);
  const location = useLocation();
  const isCurrentCompanyPath = location.pathname.includes("/companies");

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        sideMenuRef.current &&
        !sideMenuRef.current.contains(event.target as HTMLElement)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsOpen]);

  const homePageLinks = [
    {
      label: i18n.t("label.allShareLedgers"),
      to: `${APP_ROUTE.HOME}`,
      icon: <Books />,
    },
    {
      label: i18n.t("assets.Title"),
      to: `${APP_ROUTE.HOME}#asset`,
      icon: <ChartPieSlice />,
    },
  ];

  if (!isOpen) {
    return null;
  }

  return (
    <>
      <div className="tw-fixed tw-inset-0 tw-bg-gray-800 tw-bg-opacity-50" />
      <div
        ref={sideMenuRef}
        className="tw-justify-top tw-fixed tw-inset-0 tw-z-20 tw-ml-10 tw-mt-0 tw-flex tw-flex-col tw-items-start tw-space-y-6 tw-bg-white tw-px-0 tw-py-0 tw-text-center md:tw-hidden"
      >
        <div className="tw-justify-top tw-fixed tw-inset-0 tw-z-20 tw-ml-10 tw-mt-0 tw-flex tw-flex-col tw-items-start tw-space-y-0 tw-overflow-y-scroll tw-bg-white tw-px-0 tw-py-0 tw-text-center md:tw-hidden">
          <div className="tw-flex tw-w-full tw-items-center tw-justify-between tw-border-b-0 tw-py-3 tw-pl-6 tw-pr-3">
            {isCurrentCompanyPath ? (
              <div className="tw-flex tw-items-center tw-gap-5">
                <Book className="tw-h-12 tw-w-12 tw-rounded-sm tw-bg-companymenu tw-p-1  tw-text-white" />
                <p className="tw-w-48 tw-truncate tw-text-left tw-text-xl tw-text-body">
                  {currentCompany?.name}
                </p>
              </div>
            ) : (
              <LogoIcon className="tw-text-black" />
            )}
            <button
              type="button"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <CrossIcon className="tw-m-6 tw-text-black" />
            </button>
          </div>
          {isCurrentCompanyPath && <hr className="tw-h-1 tw-w-full" />}
          <div className={clsxm("tw-w-full tw-py-0")}>
            <ul className="tw-flex tw-flex-col tw-text-left">
              {companyLinks?.map(
                ({ key, label, to, onClick, icon, isBeta }) => (
                  <>
                    {to ? (
                      <NavLink
                        key={key}
                        className="tw-black-white tw-text-md tw-px-6 tw-py-6 tw-font-medium"
                        to={to}
                        onClick={() => setIsOpen(false)}
                      >
                        <div className="tw-flex tw-gap-2">
                          {icon}
                          {label}
                          <div className="tw-relative">
                            {isBeta && (
                              <div className="tw-absolute tw--right-2 tw--top-2 tw-rounded tw-bg-blue-100 tw-px-1 tw-text-xs tw-font-medium tw-text-black">
                                {i18n.t("label.beta")}
                              </div>
                            )}
                          </div>
                        </div>
                      </NavLink>
                    ) : (
                      <button
                        key={key}
                        className="tw-text-md tw-px-6 tw-py-6 tw-font-medium tw-text-neutral-800"
                        type="button"
                        onClick={() => {
                          if (onClick !== undefined) {
                            onClick();
                          }
                          setIsOpen(false);
                        }}
                      >
                        <div className="tw-flex tw-gap-2">
                          {icon}
                          {label}
                          <div className="tw-relative">
                            {isBeta && (
                              <div className="tw-absolute tw--right-2 tw--top-2 tw-rounded tw-bg-blue-100 tw-px-1 tw-text-xs tw-font-medium tw-text-black">
                                {i18n.t("label.beta")}
                              </div>
                            )}
                          </div>
                        </div>
                      </button>
                    )}
                  </>
                )
              )}
            </ul>
          </div>
          <hr className="tw-h-1 tw-w-full" />
          <div className="tw-w-full">
            <ul className="tw-flex tw-flex-col tw-text-left">
              {homePageLinks.map(({ label, to, icon }) => (
                <NavLink
                  key={to}
                  className="tw-black-white tw-text-md tw-py-6 tw-font-medium"
                  to={to}
                  onClick={() => setIsOpen(false)}
                >
                  <div className="tw-flex tw-gap-2 tw-px-6">
                    {icon}
                    {label}
                  </div>
                </NavLink>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export { SideMenu };
