import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import {
  useLedgerQuery,
  useShareholdersQuery,
  useShareTypesQuery,
} from "../../../api/blockchain/company";
import { useEntitiesQuery } from "../../../api/rest/entities";
import {
  useIncreaseShareCapital,
  useIncreaseShareCapitalBonusIssue,
} from "../../../api/rest/events";
import { Button } from "../../../components/design-system/Button";
import { Description } from "../../../components/design-system/Description";
import { DistributionProgress } from "../../../components/design-system/DistributionProgress";
import {
  FormError,
  FormErrorList,
  FormGroup,
  FormLabel,
} from "../../../components/design-system/FormGroup";
import { CrossIcon } from "../../../components/design-system/icons";
import { Input } from "../../../components/design-system/Input";
import { notify } from "../../../components/design-system/Notifications";
import { RadioGroup } from "../../../components/design-system/RadioGroup";
import type { TEventSummaryMetric } from "../../../components/EventSummary";
import {
  EventFormWrapper,
  EventSummarySectionList,
} from "../../../components/EventSummary";
import { PageWrapper } from "../../../components/PageWrapper";
import { ShareBlocksList } from "../../../components/ShareBlocks/List";
import useLatestVersion from "../../../hooks/useLatestVersion";
import { APP_ROUTE } from "../../../routes/constants";
import type { CompanyInformation } from "../../../types/models/administration";
import type { CompanyInvolvement } from "../../../types/models/company";
import type {
  TDraftShareBlock,
  TDraftShareType,
} from "../../../types/models/draft";
import type { Ledger } from "../../../types/models/shares";
import { dateToIsoString } from "../../../utils/date";
import {
  generateNewBlocksProrata,
  multiplyShares,
} from "../../../utils/shares";

type IncreaseCapitalProps = {
  currentCompany: CompanyInvolvement | CompanyInformation;
};

type ActionType = "basic" | "newshares";

type ActionOption = { value: ActionType; title: string; description: string };

type FormProps = {
  date: string;
  type: ActionType;
  shareCapital: number;
  shareBlocks: TDraftShareBlock[];
};

const getShareBlocksValidation = (
  shareBlocks: TDraftShareBlock[],
  sharesToAdd: number
) => {
  const numberOfSharesDiff =
    (shareBlocks.length > 0
      ? (shareBlocks.at(-1)?.end || 0) - (shareBlocks.at(0)?.start || 0) + 1
      : 0) - sharesToAdd;

  return { numberOfSharesDiff };
};

const getMetrics = (formValues: FormProps, ledgerData?: Ledger) => {
  const beforeShareCapital = ledgerData?.capital || 1;
  const beforeTotalShares = ledgerData?.shares.total || 1;
  const beforeQuotaValue = beforeShareCapital / beforeTotalShares;

  if (formValues.type === "newshares") {
    const afterShareCapital = formValues.shareCapital
      ? beforeShareCapital - -formValues.shareCapital
      : beforeShareCapital;

    const afterTotalShares = formValues.shareCapital
      ? multiplyShares(
          afterShareCapital / beforeShareCapital,
          beforeTotalShares
        )
      : beforeTotalShares;

    return {
      shareCapital: { before: beforeShareCapital, after: afterShareCapital },
      quotaValue: { before: beforeQuotaValue, after: beforeQuotaValue },
      numberOfShares: { before: beforeTotalShares, after: afterTotalShares },
    };
  }

  const afterShareCapital = formValues.shareCapital
    ? beforeShareCapital - -formValues.shareCapital
    : beforeShareCapital;

  return {
    shareCapital: { before: beforeShareCapital, after: afterShareCapital },
    quotaValue: {
      before: beforeQuotaValue,
      after: afterShareCapital / beforeTotalShares,
    },
    numberOfShares: { before: beforeTotalShares, after: beforeTotalShares },
  };
};

const IncreaseCapital = ({ currentCompany }: IncreaseCapitalProps) => {
  const eventsPath = `${APP_ROUTE.COMPANIES}/${currentCompany.orgNumber}/events`;
  const i18n = useTranslation();
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    register,
    setValue,
    formState,
    watch,
    setError,
  } = useForm<FormProps>({
    mode: "onChange",
    defaultValues: { shareBlocks: [] },
  });
  const lastEventDate = useLatestVersion();
  const ledgerQuery = useLedgerQuery(currentCompany.orgNumber, "");
  const entitiesQuery = useEntitiesQuery(currentCompany.orgNumber);
  const shareTypesQuery = useShareTypesQuery(currentCompany.orgNumber, "");
  const shareHoldersQuery = useShareholdersQuery(currentCompany.orgNumber);
  const allEntities = entitiesQuery.data || [];
  const shareHolders = shareHoldersQuery.data || [];
  const existingHolders = allEntities.filter((entity) =>
    shareHolders.some((element) => element.id === entity.id)
  );
  const formValues = watch();

  const metrics = getMetrics(formValues, ledgerQuery.data);
  const metricsWithLabel: Record<string, TEventSummaryMetric> = {
    shareCapital: {
      label: `${i18n.t("label.shareCapital")} (${
        currentCompany.settings?.currency
      })`,
      format: "number",
      ...metrics.shareCapital,
    },
    quotaValue: {
      label: `${i18n.t("label.quotaValue")} (${
        currentCompany.settings?.currency
      })`,
      format: "number",
      ...metrics.quotaValue,
    },
    numberOfShares: {
      label: i18n.t("label.shares"),
      format: "number",
      ...metrics.numberOfShares,
    },
  };
  const modifier = metrics.numberOfShares.after / metrics.numberOfShares.before;

  useEffect(() => {
    setValue(
      "shareBlocks",
      generateNewBlocksProrata(
        modifier,
        shareHoldersQuery.data || [],
        ledgerQuery.data?.shares.lastNumber || 0
      )
    );
  }, [modifier]);

  const shareTypes: TDraftShareType[] =
    shareTypesQuery.data?.map((shareType) => ({
      ...shareType,
      numberOfShares: 0,
    })) ?? [];

  const handleSuccess = () => {
    notify(
      i18n.t("events.success", {
        name: i18n.t("events.increaseCapital.title"),
      }),
      { type: "success" }
    );
    navigate(eventsPath);
  };
  const increaseShareCapitalMutation = useIncreaseShareCapital(
    currentCompany.orgNumber,
    { onSuccess: handleSuccess }
  );

  const increaseShareCapitalBonusIssueMutation =
    useIncreaseShareCapitalBonusIssue(currentCompany.orgNumber, {
      onSuccess: handleSuccess,
    });

  const mutation = {
    basic: increaseShareCapitalMutation,
    newshares: increaseShareCapitalBonusIssueMutation,
  }[formValues.type || "basic"];

  const onSubmit = (data: FormProps) => {
    if (shareBlocksValidation.numberOfSharesDiff !== 0) {
      setError("shareBlocks", {
        type: "manual",
        message: i18n.t("error.verification.shares.issue"),
      });
      return;
    }
    if (data.type === "newshares") {
      increaseShareCapitalBonusIssueMutation.mutate({
        date: data.date,
        amount: data.shareCapital,
        shareRanges: data.shareBlocks,
      });
    } else {
      increaseShareCapitalMutation.mutate({
        date: data.date,
        amount: data.shareCapital,
      });
    }
  };

  const typeOptions: ActionOption[] = [
    {
      value: "basic",
      title: i18n.t("events.increaseCapital.form.type.option1.title"),
      description: i18n.t(
        "events.increaseCapital.form.type.option1.description"
      ),
    },
    {
      value: "newshares",
      title: i18n.t("events.increaseCapital.form.type.option2.title"),
      description: i18n.t(
        "events.increaseCapital.form.type.option2.description"
      ),
    },
  ];

  const shareBlocksValidation = getShareBlocksValidation(
    formValues.shareBlocks,
    metrics.numberOfShares.after - metrics.numberOfShares.before
  );

  return (
    <PageWrapper data-testid="increase-capital">
      <header className="tw-flex tw-justify-between tw-pb-6">
        <div>
          <h4>{i18n.t("events.increaseCapital.title")}</h4>
        </div>
        <Link to={eventsPath} className="tw-text-body">
          <CrossIcon className="tw-h-6 tw-w-6" />
        </Link>
      </header>
      <EventFormWrapper
        summary={
          <EventSummarySectionList metrics={Object.values(metricsWithLabel)} />
        }
      >
        <form className="tw-space-y-6" onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <FormLabel htmlFor="date">{i18n.t("label.date")}</FormLabel>
            <Controller
              control={control}
              render={({
                field: { ref, name, onChange, value },
                fieldState,
              }) => (
                <>
                  <Input
                    id="date"
                    value={value}
                    ref={ref}
                    name={name}
                    onChange={onChange}
                    type="date"
                    className="tw-w-full"
                    max={dateToIsoString(new Date())}
                    min={lastEventDate && dateToIsoString(lastEventDate.date)}
                  />
                  <FormError>{fieldState.error?.message}</FormError>
                </>
              )}
              name="date"
              rules={{ required: i18n.t("error.validation.required") }}
            />
          </FormGroup>
          <FormGroup>
            <Controller
              control={control}
              render={({ field: { name, onChange, value }, fieldState }) => (
                <>
                  <RadioGroup
                    value={value || null}
                    onChange={onChange}
                    name={name}
                  >
                    <RadioGroup.Label>
                      <FormLabel htmlFor={name} className="tw-mb-4">
                        {i18n.t("events.increaseCapital.form.type.label")}
                      </FormLabel>
                    </RadioGroup.Label>
                    <div className="tw-space-y-2">
                      {typeOptions.map((option) => (
                        <RadioGroup.Option
                          key={option.value}
                          value={option.value}
                        >
                          {({ checked }) => (
                            <RadioGroup.OptionContent checked={checked}>
                              <Description
                                title={option.title}
                                description={option.description}
                              />
                            </RadioGroup.OptionContent>
                          )}
                        </RadioGroup.Option>
                      ))}
                    </div>
                  </RadioGroup>
                  <FormError>{fieldState.error?.message}</FormError>
                </>
              )}
              name="type"
              rules={{ required: i18n.t("error.validation.required") }}
            />
          </FormGroup>
          {formValues.type && (
            <FormGroup>
              <FormLabel htmlFor="shareCapital">
                {i18n.t("events.increaseCapital.form.shareCapital.label")}
              </FormLabel>
              <Input
                id="shareCapital"
                {...register("shareCapital", {
                  required: i18n.t("error.validation.required"),
                  valueAsNumber: true,
                  min: {
                    value: 1,
                    message: i18n.t("error.validation.range.min", { min: 1 }),
                  },
                })}
                type="number"
                step={0.01}
                prefix={currentCompany.settings?.currency}
              />
              <FormError>{formState.errors.shareCapital?.message}</FormError>
            </FormGroup>
          )}
          {formValues.type === "newshares" && modifier > 1 && (
            <Controller
              control={control}
              name="shareBlocks"
              render={({ field: { onChange, value }, fieldState }) => (
                <>
                  <ShareBlocksList
                    value={value}
                    onChange={onChange}
                    entitiesQuery={
                      { ...entitiesQuery, data: existingHolders } as ReturnType<
                        typeof useEntitiesQuery
                      >
                    }
                    currentCompany={currentCompany}
                    shareTypes={shareTypes}
                    offset={0}
                    disableNewEntity
                  />
                  <FormError>{fieldState.error?.message}</FormError>
                </>
              )}
              rules={{ required: i18n.t("error.validation.required") }}
            />
          )}
          {modifier > 1 && (
            <DistributionProgress
              diff={shareBlocksValidation.numberOfSharesDiff}
            />
          )}
          {mutation.error && <FormErrorList error={mutation.error} />}
          <div className="tw-full tw-flex tw-justify-end tw-space-x-2 tw-py-4">
            <Link to="../">
              <Button>{i18n.t("label.cancel")}</Button>
            </Link>
            <Button
              variant="solid"
              color="primary"
              type="submit"
              isLoading={mutation.isLoading}
            >
              {i18n.t("label.save")}
            </Button>
          </div>
        </form>
      </EventFormWrapper>
    </PageWrapper>
  );
};

export default IncreaseCapital;
