import type { RawTxResponse } from "@capchapdev/admin-api";
import type { UseMutationOptions } from "@tanstack/react-query";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useBlockchainClient } from "../../context/blockchain";
import type {
  PledgedSharesUpdate,
  ReclassificationClass,
  ReclassificationRange,
  ReverseShareSplit,
  ShareCapitalChange,
  ShareCapitalIncreaseBonusIssue,
  ShareCertificatesUpdate,
  ShareSplit,
  SharesReductionCancelShares,
  SharesTransfer,
  SharesTransferRange,
  ShareTypeUpdate,
} from "../../types/models/shares";
import * as monitoring from "../../utils/monitoring";
import type { IRequestError } from "..";
import useClient, { URL } from "./client";
import { RawTxResponseSchema } from "./users";

const useIssueShares = (
  orgNumber: string,
  draftName: string,
  options?: UseMutationOptions<unknown, IRequestError>
) => {
  const blockchainClient = useBlockchainClient();
  const client = useClient({ hasAuth: true });

  return useMutation<unknown, IRequestError>(async () => {
    const response = await client<RawTxResponse>(
      `${URL.ADMIN}/Transaction/CompleteNewIssueOfShares/${orgNumber}/${draftName}`
    );

    const result = RawTxResponseSchema.safeParse(response);

    if (!result.success) {
      monitoring.captureException(result.error, {
        contexts: { response, result },
      });

      return blockchainClient.sendTransaction(response.rawTx);
    }

    return blockchainClient.sendTransaction(result.data.rawTx);
  }, options);
};

const useApproveRule = (
  orgNumber: string,
  options?: UseMutationOptions<void, IRequestError, string>
) => {
  const blockchainClient = useBlockchainClient();
  const queryClient = useQueryClient();
  const client = useClient({ hasAuth: true });

  return useMutation<void, IRequestError, string>(
    async (signature) => {
      const response = await client<RawTxResponse>(
        `${URL.ADMIN}/Transaction/ApproveLedger/Rule/Approve/${orgNumber}`,
        { method: "PUT", body: { signature } }
      );

      const result = RawTxResponseSchema.safeParse(response);

      if (!result.success) {
        monitoring.captureException(result.error, {
          contexts: { response, result },
        });

        return blockchainClient.sendTransaction(response.rawTx);
      }

      return blockchainClient.sendTransaction(result.data.rawTx);
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(["ApprovalRuleProposal", orgNumber]);
        queryClient.invalidateQueries(["approvalInfo", orgNumber]);
        queryClient.invalidateQueries(["approvalActive", orgNumber]);
      },
      ...options,
    }
  );
};

const useConfirmLedgerAsOfficialAndApproveRule = (
  orgNumber: string,
  options?: UseMutationOptions<unknown, IRequestError, string>
) => {
  const blockchainClient = useBlockchainClient();
  const queryClient = useQueryClient();
  const client = useClient({ hasAuth: true });

  return useMutation<unknown, IRequestError, string>(
    async (signature) => {
      const response = await client<RawTxResponse>(
        `${URL.ADMIN}/Transaction/ConfirmLedgerAsOfficialAndApproveLedger/Rule/Approve/${orgNumber}`,
        {
          method: "PUT",
          body: {
            signature,
          },
        }
      );

      const result = RawTxResponseSchema.safeParse(response);

      if (!result.success) {
        monitoring.captureException(result.error, {
          contexts: { response, result },
        });

        return blockchainClient.sendTransaction(response.rawTx);
      }

      return blockchainClient.sendTransaction(result.data.rawTx);
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(["ApprovalRuleProposal", orgNumber]);
        queryClient.invalidateQueries(["approvalInfo", orgNumber]);
        queryClient.invalidateQueries(["approvalActive", orgNumber]);
        queryClient.invalidateQueries([
          "userConfirmedLedgerAsOfficial",
          orgNumber,
        ]);
      },
      ...options,
    }
  );
};

type ConfirmLedgerAsOfficialAndApprovalPercentageRequest =
  ProposeBoardPercentageRuleRequest & {
    signature: string;
  };

const useConfirmLedgerAsOfficialAndApprovalPercentage = (
  orgNumber: string,
  options?: UseMutationOptions<
    unknown,
    IRequestError,
    ConfirmLedgerAsOfficialAndApprovalPercentageRequest
  >
) => {
  const blockchainClient = useBlockchainClient();
  const queryClient = useQueryClient();
  const client = useClient({ hasAuth: true });

  return useMutation<
    unknown,
    IRequestError,
    ConfirmLedgerAsOfficialAndApprovalPercentageRequest
  >(
    async (data) => {
      const response = await client<RawTxResponse>(
        `${URL.ADMIN}/Transaction/ConfirmLedgerAsOfficialAndApproveLedger/Rule/ProposeBoardPercentage/${orgNumber}`,
        {
          body: {
            percentage: Math.floor(data.percentage * 1000),
            signature: data.signature,
          },
          method: "PUT",
        }
      );

      const result = RawTxResponseSchema.safeParse(response);

      if (!result.success) {
        monitoring.captureException(result.error, {
          contexts: { response, result },
        });

        return blockchainClient.sendTransaction(response.rawTx);
      }

      return blockchainClient.sendTransaction(result.data.rawTx);
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(["ApprovalRuleProposal", orgNumber]);
        queryClient.invalidateQueries(["approvalActive", orgNumber]);
        queryClient.invalidateQueries(["approvalInfo", orgNumber]);
        queryClient.invalidateQueries([
          "userConfirmedLedgerAsOfficial",
          orgNumber,
        ]);
      },
      ...options,
    }
  );
};

type ProposeBoardPercentageRuleRequest = {
  percentage: number;
  signature?: string;
};

const useApprovalPercentage = (
  orgNumber: string,
  options?: UseMutationOptions<
    unknown,
    IRequestError,
    ProposeBoardPercentageRuleRequest
  >
) => {
  const blockchainClient = useBlockchainClient();
  const queryClient = useQueryClient();
  const client = useClient({ hasAuth: true });

  return useMutation<unknown, IRequestError, ProposeBoardPercentageRuleRequest>(
    async (data) => {
      const preparedData: ProposeBoardPercentageRuleRequest = {
        percentage: Math.floor(data.percentage * 1000),
        signature: data.signature,
      };
      const response = await client<RawTxResponse>(
        `${URL.ADMIN}/Transaction/ApproveLedger/Rule/ProposeBoardPercentage/${orgNumber}`,
        { body: preparedData, method: "PUT" }
      );

      const result = RawTxResponseSchema.safeParse(response);

      if (!result.success) {
        monitoring.captureException(result.error, {
          contexts: { response, result },
        });

        return blockchainClient.sendTransaction(response.rawTx);
      }

      return blockchainClient.sendTransaction(result.data.rawTx);
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(["ApprovalRuleProposal", orgNumber]);
        queryClient.invalidateQueries(["approvalActive", orgNumber]);
        queryClient.invalidateQueries(["approvalInfo", orgNumber]);
      },
      ...options,
    }
  );
};

type ProposeSpecificUsersRuleRequest = {
  users: string[];
  signature?: string;
};

const useApprovalSpecific = (
  orgNumber: string,
  options?: UseMutationOptions<
    unknown,
    IRequestError,
    ProposeSpecificUsersRuleRequest
  >
) => {
  const blockchainClient = useBlockchainClient();
  const queryClient = useQueryClient();
  const client = useClient({ hasAuth: true });

  return useMutation<unknown, IRequestError, ProposeSpecificUsersRuleRequest>(
    async (data) => {
      const response = await client<RawTxResponse>(
        `${URL.ADMIN}/Transaction/ApproveLedger/Rule/ProposeSpecificUsers/${orgNumber}`,
        { body: data, method: "PUT" }
      );

      const result = RawTxResponseSchema.safeParse(response);

      if (!result.success) {
        monitoring.captureException(result.error, {
          contexts: { response, result },
        });

        return blockchainClient.sendTransaction(response.rawTx);
      }

      return blockchainClient.sendTransaction(result.data.rawTx);
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(["ApprovalRuleProposal", orgNumber]);
        queryClient.invalidateQueries(["approvalActive", orgNumber]);
        queryClient.invalidateQueries(["approvalInfo", orgNumber]);
      },
      ...options,
    }
  );
};

type ConfirmLedgerAsOfficialAndProposeSpecificUsersRuleRequest =
  ProposeSpecificUsersRuleRequest & {
    signature: string;
  };

const useConfirmLedgerAsOfficialAndApprovalSpecific = (
  orgNumber: string,
  options?: UseMutationOptions<
    unknown,
    IRequestError,
    ConfirmLedgerAsOfficialAndProposeSpecificUsersRuleRequest
  >
) => {
  const blockchainClient = useBlockchainClient();
  const queryClient = useQueryClient();
  const client = useClient({ hasAuth: true });

  return useMutation<
    unknown,
    IRequestError,
    ConfirmLedgerAsOfficialAndProposeSpecificUsersRuleRequest
  >(
    async (data) => {
      const response = await client<RawTxResponse>(
        `${URL.ADMIN}/Transaction/ConfirmLedgerAsOfficialAndApproveLedger/Rule/ProposeSpecificUsers/${orgNumber}`,
        { body: data, method: "PUT" }
      );

      const result = RawTxResponseSchema.safeParse(response);

      if (!result.success) {
        monitoring.captureException(result.error, {
          contexts: { response, result },
        });

        return blockchainClient.sendTransaction(response.rawTx);
      }

      return blockchainClient.sendTransaction(result.data.rawTx);
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(["ApprovalRuleProposal", orgNumber]);
        queryClient.invalidateQueries(["approvalActive", orgNumber]);
        queryClient.invalidateQueries(["approvalInfo", orgNumber]);
        queryClient.invalidateQueries([
          "userConfirmedLedgerAsOfficial",
          orgNumber,
        ]);
      },
      ...options,
    }
  );
};

const useTransferShares = (
  orgNumber: string,
  options?: UseMutationOptions<unknown, IRequestError, SharesTransfer>
) => {
  const blockchainClient = useBlockchainClient();
  const client = useClient({ hasAuth: true });

  return useMutation<unknown, IRequestError, SharesTransfer>(async (data) => {
    const response = await client<RawTxResponse>(
      `${URL.ADMIN}/Transaction/TransferShares/${orgNumber}`,
      { body: { transfer: data }, method: "PUT" }
    );

    const result = RawTxResponseSchema.safeParse(response);

    if (!result.success) {
      monitoring.captureException(result.error, {
        contexts: { response, result },
      });

      return blockchainClient.sendTransaction(response.rawTx);
    }

    return blockchainClient.sendTransaction(result.data.rawTx);
  }, options);
};

const useTransferSharesRange = (
  orgNumber: string,
  options?: UseMutationOptions<unknown, IRequestError, SharesTransferRange>
) => {
  const blockchainClient = useBlockchainClient();
  const client = useClient({ hasAuth: true });

  return useMutation<unknown, IRequestError, SharesTransferRange>(
    async (data) => {
      const response = await client<RawTxResponse>(
        `${URL.ADMIN}/Transaction/TransferShares/Range/${orgNumber}`,
        { body: data, method: "PUT" }
      );

      const result = RawTxResponseSchema.safeParse(response);

      if (!result.success) {
        monitoring.captureException(result.error, {
          contexts: { response, result },
        });

        return blockchainClient.sendTransaction(response.rawTx);
      }

      return blockchainClient.sendTransaction(result.data.rawTx);
    },
    options
  );
};

const useSplitShares = (
  orgNumber: string,
  options?: UseMutationOptions<unknown, IRequestError, ShareSplit>
) => {
  const blockchainClient = useBlockchainClient();
  const client = useClient({ hasAuth: true });

  return useMutation<unknown, IRequestError, ShareSplit>(async (data) => {
    const response = await client<RawTxResponse>(
      `${URL.ADMIN}/Transaction/SplitShares/${orgNumber}`,
      { body: data, method: "PUT" }
    );

    const result = RawTxResponseSchema.safeParse(response);

    if (!result.success) {
      monitoring.captureException(result.error, {
        contexts: { response, result },
      });

      return blockchainClient.sendTransaction(response.rawTx);
    }

    return blockchainClient.sendTransaction(result.data.rawTx);
  }, options);
};

const useReverseShareSplit = (
  orgNumber: string,
  options?: UseMutationOptions<unknown, IRequestError, ReverseShareSplit>
) => {
  const blockchainClient = useBlockchainClient();
  const client = useClient({ hasAuth: true });

  return useMutation<unknown, IRequestError, ReverseShareSplit>(
    async (data) => {
      const response = await client<RawTxResponse>(
        `${URL.ADMIN}/Transaction/ReverseSplitShares/${orgNumber}`,
        { body: data, method: "PUT" }
      );

      const result = RawTxResponseSchema.safeParse(response);

      if (!result.success) {
        monitoring.captureException(result.error, {
          contexts: { response, result },
        });

        return blockchainClient.sendTransaction(response.rawTx);
      }

      return blockchainClient.sendTransaction(result.data.rawTx);
    },
    options
  );
};

const useShareTypeUpdate = (
  orgNumber: string,
  name: string,
  options?: UseMutationOptions<unknown, IRequestError, ShareTypeUpdate>
) => {
  const blockchainClient = useBlockchainClient();
  const client = useClient({ hasAuth: true });

  return useMutation<unknown, IRequestError, ShareTypeUpdate>(async (data) => {
    const response = await client<RawTxResponse>(
      `${URL.ADMIN}/Transaction/UpdateShareClass/${orgNumber}/${name}`,
      { body: data, method: "PUT" }
    );

    const result = RawTxResponseSchema.safeParse(response);

    if (!result.success) {
      monitoring.captureException(result.error, {
        contexts: { response, result },
      });

      return blockchainClient.sendTransaction(response.rawTx);
    }

    return blockchainClient.sendTransaction(result.data.rawTx);
  }, options);
};

const useShareCertificatesUpdateMutation = (
  orgNumber: string,
  options?: UseMutationOptions<unknown, IRequestError, ShareCertificatesUpdate>
) => {
  const blockchainClient = useBlockchainClient();
  const client = useClient({ hasAuth: true });

  return useMutation<unknown, IRequestError, ShareCertificatesUpdate>(
    async (data) => {
      const response = await client<RawTxResponse>(
        `${URL.ADMIN}/Transaction/UpdateShareCertificates/${orgNumber}`,
        { body: data, method: "PUT" }
      );

      const result = RawTxResponseSchema.safeParse(response);

      if (!result.success) {
        monitoring.captureException(result.error, {
          contexts: { response, result },
        });

        return blockchainClient.sendTransaction(response.rawTx);
      }

      return blockchainClient.sendTransaction(result.data.rawTx);
    },
    options
  );
};

const usePledgedSharesMutation = (
  orgNumber: string,
  options?: UseMutationOptions<unknown, IRequestError, PledgedSharesUpdate>
) => {
  const blockchainClient = useBlockchainClient();
  const client = useClient({ hasAuth: true });

  return useMutation<unknown, IRequestError, PledgedSharesUpdate>(
    async (data) => {
      const response = await client<RawTxResponse>(
        `${URL.ADMIN}/Transaction/UpdatePledgedShares/${orgNumber}`,
        { body: data, method: "PUT" }
      );

      const result = RawTxResponseSchema.safeParse(response);

      if (!result.success) {
        monitoring.captureException(result.error, {
          contexts: { response, result },
        });

        return blockchainClient.sendTransaction(response.rawTx);
      }

      return blockchainClient.sendTransaction(result.data.rawTx);
    },
    options
  );
};

const useReclassifySharesClass = (
  orgNumber: string,
  options?: UseMutationOptions<unknown, IRequestError, ReclassificationClass>
) => {
  const blockchainClient = useBlockchainClient();
  const client = useClient({ hasAuth: true });

  return useMutation<unknown, IRequestError, ReclassificationClass>(
    async (data) => {
      const response = await client<RawTxResponse>(
        `${URL.ADMIN}/Transaction/ReclassifyShares/Class/${orgNumber}`,
        { body: data, method: "PUT" }
      );

      const result = RawTxResponseSchema.safeParse(response);

      if (!result.success) {
        monitoring.captureException(result.error, {
          contexts: { response, result },
        });

        return blockchainClient.sendTransaction(response.rawTx);
      }

      return blockchainClient.sendTransaction(result.data.rawTx);
    },
    options
  );
};

const useReclassifySharesRange = (
  orgNumber: string,
  options?: UseMutationOptions<unknown, IRequestError, ReclassificationRange>
) => {
  const blockchainClient = useBlockchainClient();
  const client = useClient({ hasAuth: true });

  return useMutation<unknown, IRequestError, ReclassificationRange>(
    async (data) => {
      const response = await client<RawTxResponse>(
        `${URL.ADMIN}/Transaction/ReclassifyShares/Range/${orgNumber}`,
        { body: data, method: "PUT" }
      );

      const result = RawTxResponseSchema.safeParse(response);

      if (!result.success) {
        monitoring.captureException(result.error, {
          contexts: { response, result },
        });

        return blockchainClient.sendTransaction(response.rawTx);
      }

      return blockchainClient.sendTransaction(result.data.rawTx);
    },
    options
  );
};

const useReduceShareCapital = (
  orgNumber: string,
  options?: UseMutationOptions<unknown, IRequestError, ShareCapitalChange>
) => {
  const blockchainClient = useBlockchainClient();
  const client = useClient({ hasAuth: true });

  return useMutation<unknown, IRequestError, ShareCapitalChange>(
    async (data) => {
      const response = await client<RawTxResponse>(
        `${URL.ADMIN}/Transaction/DecreaseShareCapital/${orgNumber}`,
        { body: data, method: "PUT" }
      );

      const result = RawTxResponseSchema.safeParse(response);

      if (!result.success) {
        monitoring.captureException(result.error, {
          contexts: { response, result },
        });

        return blockchainClient.sendTransaction(response.rawTx);
      }

      return blockchainClient.sendTransaction(result.data.rawTx);
    },
    options
  );
};

const useReduceShareCapitalCancelShares = (
  orgNumber: string,
  options?: UseMutationOptions<
    unknown,
    IRequestError,
    SharesReductionCancelShares
  >
) => {
  const blockchainClient = useBlockchainClient();
  const client = useClient({ hasAuth: true });

  return useMutation<unknown, IRequestError, SharesReductionCancelShares>(
    async (data) => {
      const response = await client<RawTxResponse>(
        `${URL.ADMIN}/Transaction/DecreaseShareCapital/CancelShares/${orgNumber}`,
        { body: data, method: "PUT" }
      );

      const result = RawTxResponseSchema.safeParse(response);

      if (!result.success) {
        monitoring.captureException(result.error, {
          contexts: { response, result },
        });

        return blockchainClient.sendTransaction(response.rawTx);
      }

      return blockchainClient.sendTransaction(result.data.rawTx);
    },
    options
  );
};

const useIncreaseShareCapital = (
  orgNumber: string,
  options?: UseMutationOptions<unknown, IRequestError, ShareCapitalChange>
) => {
  const blockchainClient = useBlockchainClient();
  const client = useClient({ hasAuth: true });

  return useMutation<unknown, IRequestError, ShareCapitalChange>(
    async (data) => {
      const response = await client<RawTxResponse>(
        `${URL.ADMIN}/Transaction/IncreaseShareCapital/${orgNumber}`,
        { body: data, method: "PUT" }
      );

      const result = RawTxResponseSchema.safeParse(response);

      if (!result.success) {
        monitoring.captureException(result.error, {
          contexts: { response, result },
        });

        return blockchainClient.sendTransaction(response.rawTx);
      }

      return blockchainClient.sendTransaction(result.data.rawTx);
    },
    options
  );
};

const useIncreaseShareCapitalBonusIssue = (
  orgNumber: string,
  options?: UseMutationOptions<unknown, IRequestError, ShareCapitalChange>
) => {
  const blockchainClient = useBlockchainClient();
  const client = useClient({ hasAuth: true });

  return useMutation<unknown, IRequestError, ShareCapitalIncreaseBonusIssue>(
    async (data) => {
      const response = await client<RawTxResponse>(
        `${URL.ADMIN}/Transaction/IncreaseShareCapital/BonusIssue/${orgNumber}`,
        { body: data, method: "PUT" }
      );

      const result = RawTxResponseSchema.safeParse(response);

      if (!result.success) {
        monitoring.captureException(result.error, {
          contexts: { response, result },
        });

        return blockchainClient.sendTransaction(response.rawTx);
      }

      return blockchainClient.sendTransaction(result.data.rawTx);
    },
    options
  );
};

export {
  useApprovalPercentage,
  useApprovalSpecific,
  useApproveRule,
  useConfirmLedgerAsOfficialAndApprovalPercentage,
  useConfirmLedgerAsOfficialAndApprovalSpecific,
  useConfirmLedgerAsOfficialAndApproveRule,
  useIncreaseShareCapital,
  useIncreaseShareCapitalBonusIssue,
  useIssueShares,
  usePledgedSharesMutation,
  useReclassifySharesClass,
  useReclassifySharesRange,
  useReduceShareCapital,
  useReduceShareCapitalCancelShares,
  useReverseShareSplit,
  useShareCertificatesUpdateMutation,
  useShareTypeUpdate,
  useSplitShares,
  useTransferShares,
  useTransferSharesRange,
};
