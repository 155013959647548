import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { IconDescription } from "../../IconDescription";
import { ArrowRightIcon, CrossIcon } from "../icons";

type Notification = {
  link: string;
  message: string;
  linkText: string;
};

const Banner = ({
  link,
  message,
  linkText,
}: {
  link: string;
  message: string;
  linkText: string;
}) => {
  const i18n = useTranslation();
  const [isVisible, setIsVisible] = useState(true);
  const handleClose = () => setIsVisible(false);

  if (!isVisible) {
    return null;
  }

  return (
    <div className="tw-bg-kvanta-purple tw-py-5 print:tw-hidden">
      <div className="tw-mx-auto tw-flex tw-max-w-7xl tw-items-center tw-gap-x-3 tw-px-3 tw-text-sm tw-text-kvanta-purple-dark md:tw-px-6">
        <div
          className="tw-flex tw-w-full tw-items-center tw-justify-between"
          data-testid="pending-events-count"
        >
          <div>
            <Link to={link} className="tw-text-kvanta-purple-dark">
              {message}
            </Link>
          </div>
          <div className="tw-flex tw-items-center">
            <button onClick={handleClose} type="button">
              <IconDescription
                title={i18n.t("pendingEventsNotification.close")}
                icon={<CrossIcon />}
                iconFirst={false}
                theme="purple"
                className="tw-cursor-pointer tw-gap-2 tw-px-4 max-md:tw-hidden"
              />
            </button>
            <Link to={link} className="tw-text-kvanta-purple-dark">
              <IconDescription
                title={linkText}
                icon={<ArrowRightIcon />}
                iconFirst={false}
                theme="purple"
                className="tw-gap-2 tw-px-4"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Banner };
export type { Notification };
